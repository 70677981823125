import {Paper, Stack, Center, Image, Text, Button, Loader} from "@mantine/core";
import {AccountContext} from "../../misc/Accounts";
import {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {API_URL, CABINET_URL, DISCORD_URL} from "../../config";
import successImage from "../../images/success.png"
import {getCabinetText} from "../../Locale/Cabinet";
import {TogglerContext} from "../../App";
import {ShowFailureNotification} from "../../misc/design/Notifications";
import ErrorApiResponse from "../../Errors/ErrorApiResponse";


export default function Success() {
    const {payment} = useParams()
    const {changeToggle} = useContext(TogglerContext);
    const {getSession} = useContext(AccountContext)
    const [loading, setLoading] = useState(true)
    const [paymentData, setPaymentData] = useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPaymentData = useCallback((headers) => {
        axios.post(`${API_URL}/payment/${payment}/success`, {}, {headers}).then(res => {
            if (res.data.message.length) {
                setPaymentData(res.data.message[0]);
                setLoading(false);
                return;
            }
            setTimeout(() => getPaymentData(headers), 30000);
        }).catch(err => {
            ShowFailureNotification(ErrorApiResponse(err.response.data.message))
            console.error(err.response.data.message)
        })
    })

    useEffect(() => {}, [changeToggle])

    useEffect(() => {
        getSession().then(({headers, user}) => getPaymentData(headers, user))
    }, [getPaymentData, getSession, payment])

    return (
        <Center>
            <Paper p="xl" sx={{minWidth: 500, position: 'relative', minHeight: 350}}>
                {loading ? <>
                        <Center>
                            <Stack sx={{minHeight: 300}}>
                                <Loader size="xl" sx={{margin: '50px auto'}}/>
                                <Stack justify="space-between" >
                                    <Text align="center" sx={{marginBottom: 15}}>
                                        {getCabinetText('waitingConfirm')}<br/>
                                        {getCabinetText('ifNothing5')}
                                    </Text>
                                    <Button onClick={() => {window.location.href = `${DISCORD_URL}`}}>Discord</Button>
                                </Stack>
                            </Stack>
                        </Center>
                    </> :
                    paymentData !== null && !loading &&
                    <Stack align="center" justify="space-around">
                        <Image
                            radius="md"
                            src={successImage}
                            alt="Success Image"
                            width={200}
                        />
                        <Text size="md">{getCabinetText('successDonate')} {paymentData.amount_coins} Dares Coins</Text>
                        <Button
                            sx={{width: '50%'}}
                            onClick={() => {
                                window.location.href = `${CABINET_URL}/donate`
                            }}
                        >
                            {getCabinetText('ok')}
                        </Button>
                    </Stack>
                }
            </Paper>
        </Center>
    )
}