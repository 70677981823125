import {PROMOTION_DATE_X6} from "../config";

export const getServerName = (value) => {
    switch (value) {
        case 'endless':
            return 'Remastered x6 Endless [OLD]'
        case 'remastered-x50':
            return 'Remastered x50 Neonate'
        case 'neonate-x3':
            return 'Remastered x3 Neonate [NEW]'
        default:
            return 'Remastered x6 Endless [OLD]'
    }
}

export const isPromotionActive = () => {
    return new Date() > new Date(PROMOTION_DATE_X6)
}

const bonusData = [
    {value: 10, percent: 1, label: '+1%'},
    {value: 15, percent: 2, label: '+2%'},
    {value: 20, percent: 3, label: '+3%'},
    {value: 25, percent: 4, label: '+4%'},
    {value: 30, percent: 5, label: '+5%'},
    {value: 35, percent: 6, label: '+6%'},
    {value: 50, percent: 7, label: '+7%'},
    {value: 100, percent: 8, label: '+8%'},
    {value: 150, percent: 9, label: '+9%'},
    {value: 200, percent: 10, label: '+10%'},
]

export const calculateCoins = (valueInEur) => {
    let bonusPercent = 0
    bonusData.forEach(bonus => {
        if (valueInEur >= bonus.value) {
            bonusPercent = bonus.percent
        }
    })

    let coinsTemp = (valueInEur + (valueInEur / 100 * bonusPercent)) * 10

    if (isPromotionActive()) {
        if (valueInEur < 15) {
            coinsTemp += 25
        } else if (valueInEur >= 15 && valueInEur < 50) {
            coinsTemp += 100
        } else if (valueInEur >= 50 && valueInEur < 100) {
            coinsTemp += 400
        } else if (valueInEur >= 100) {
            coinsTemp += 1500
        }
    }

    return coinsTemp.toFixed(0)
}

export const deleteFramingSymbols = (str) => {
    if (str === undefined || str === null) return ''
    return (str).replace(/^.|.$/g, "")
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const setReferralKey = (key) => {
    setCookie('referral_key', key, 31)
}

export function getCookieValue(name) {
    const nameString = `${name}=`;
    const value = document.cookie.split('; ').find(row => row.startsWith(nameString));
    return value ? decodeURIComponent(value.split('=')[1]) : null;
}

export const is_referral_key_exist = () => {
    return document.cookie.split('; ').find((row) => row.startsWith('referral_key='))?.split('=')[1]
}