import {AppShell, Container, useMantineTheme} from "@mantine/core";
import Sidebar from "./layouts/Sidebar";
import {createContext, useContext, useEffect, useState} from "react";
import MyHeader from "./layouts/MyHeader";
import axios from "axios";
import {ACTIVE_SEVER, API_URL} from "../config";
import {AccountContext} from "../misc/Accounts";
import Loading from "../components/Loading";
import {ShowFailureNotification} from "../misc/design/Notifications";
import ErrorApiResponse from "../Errors/ErrorApiResponse";
import {useLocalStorage} from "@mantine/hooks";


export const GameAccountsContext = createContext()


export default function Cabinet(props) {
    const theme = useMantineTheme();

    const {getSession} = useContext(AccountContext)
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
    const [opened, setOpened] = useState(true);
    const [accountsLoading, setAccountsLoading] = useState(true);

    const [gameAccounts, setGameAccounts] = useState([]);

    useEffect(() => {
        window.screen.width > 769 ? setOpened(true) : setOpened(false)
    }, [])

    useEffect(() => {
        setAccountsLoading(true)
        getSession().then(async ({headers}) => {
            axios.get(`${API_URL}/accounts/list/${localStorage.getItem('server').replace(/"/gi, '')}`, {headers})
                .then(({data}) => {
                    setGameAccounts(data.message)
                    setAccountsLoading(false)
                })
                .catch(err => {
                    ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                    console.error(err.response.data.message)
                })
        })
    }, [getSession, server])

    return (
        <GameAccountsContext.Provider value={{gameAccounts, setGameAccounts, setAccountsLoading}}>
            <AppShell
                fixed
                styles={{main: {background: theme.colors.gray[0]}}}
                navbar={<Sidebar opened={opened} setOpened={setOpened}/>}
            >
                <MyHeader setOpened={setOpened}/>
                <Loading loading={accountsLoading}>
                    <Container size="xl" sx={{marginTop: 20}}>
                        {props.children}
                    </Container>
                </Loading>
            </AppShell>
        </GameAccountsContext.Provider>
    );
}

