import {Button, Card, Center, Checkbox, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import {AlertTriangle, Login, Plus} from "tabler-icons-react";
import {useContext, useEffect, useRef, useState} from "react";
import {AccountContext} from "../misc/Accounts";
import {useForm} from "@mantine/form";
import {ErrorSignIn} from "../Errors/ErrorSignIn";
import {getEnterText} from "../Locale/Enter";
import GrayButton from "./components/GrayButton";
import SelectLanguage from "./components/SelectLanguage";
import {HeaderToggleContext} from "./EnterPage";
import {ShowFailureNotification} from "../misc/design/Notifications";
import {SignInForm} from "../forms/enterForms";
import EmailInput from "../components/EmailInput";
import PassInput from "../components/PassInput";
import Welcome from "./components/Welcome";
import rememberCheck from "./components/rememberCheck";
import {isEmpty} from "../misc/misc";

export default function SignIn() {
    const theme = useMantineTheme();

    const {authenticate} = useContext(AccountContext);
    const {changeToggle} = useContext(HeaderToggleContext);
    const prevChangeToggle = useRef();

    const form = useForm(SignInForm);

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (prevChangeToggle.current !== changeToggle) {
            if (!isEmpty(form.errors)) {
                form.validate();
            }

            prevChangeToggle.current = changeToggle;
        }
    }, [changeToggle, form])

    const login = values => {
        setLoader(true)
        authenticate(values.email.toLowerCase(), values.password)
            .then(() => {
                rememberCheck(values.remember)
                window.location.href = '/';
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
                const errorMassage = ErrorSignIn(err.name)
                ShowFailureNotification(errorMassage)
                form.setErrors({email: errorMassage})
            })
    }

    return (
        <Center>
            <Stack>
                <Welcome/>
                <Card>
                    <Card.Section sx={{background: theme.fn.linearGradient(120, 'rgb(155,98,19)', 'rgba(224, 136, 33, 1)'), padding: 10}}>
                        <Group position={'apart'}>
                            <Text size="sm" sx={{color: theme.colors.gray[0]}}>{getEnterText('signIn')}</Text>
                            <SelectLanguage/>
                        </Group>
                    </Card.Section>

                    <form id={'c_form_login'} onSubmit={form.onSubmit((values) => login(values))}>
                        <Card.Section>
                            <Stack>
                                <EmailInput matomo_id={'c_input_email'} form={form}/>
                                <PassInput matomo_id={'c_input_password'} form={form}/>
                            </Stack>
                        </Card.Section>

                        <Card.Section>
                            <Group position="apart">
                                <Checkbox
                                    label={`${getEnterText('rememberMe')}?`}
                                    {...form.getInputProps('remember', {type: 'checkbox'})}
                                    id={'c_checkbox_remember'}
                                />
                                <Button id={'c_button_submit_login'} type="submit" leftIcon={<Login size={18}/>} loading={loader}>{getEnterText('login')}</Button>
                            </Group>
                        </Card.Section>
                    </form>

                    <Card.Section sx={{backgroundColor: theme.colors.gray[1]}}>
                        <Group position="center">
                            <GrayButton matomo_id={'c_button_signup'} to="/signup" leftIcon={<Plus size={18}/>}>
                                {getEnterText('registration')}
                            </GrayButton>
                            <GrayButton matomo_id={'c_button_reset_password'} to="/reminder" leftIcon={<AlertTriangle size={18}/>}>
                                {getEnterText('resetPassword')}
                            </GrayButton>
                        </Group>
                    </Card.Section>
                </Card>
            </Stack>
        </Center>
    );
}