import {getTextNew} from "../Locale/Enter";
import {ErrorTypeToMessageObj} from "../Locale/Errors";

export function SignUpExceptionHandler(err) {
    const fields_obj = {
        'CodeDeliveryFailureException': 'confirmationCode',
        'InvalidEmailRoleAccessPolicyException': 'email',
        'InvalidPasswordException': 'password',
        'InvalidSmsRoleAccessPolicyException': 'sms',
        'TooManyRequestsException': 'email',
        'UsernameExistsException': 'email',
        'DisposableEmailException': 'email',
    }

    // TODO: log notification
    const field = fields_obj[err.name] || 'email'
    return {[field]: getTextNew(ErrorTypeToMessageObj[err.name])}
}