import {useContext} from "react";
import {TogglerContext} from "../../App";
import {useLocalStorage} from "@mantine/hooks";
import {Menu, Button} from "@mantine/core";
import {getServerName} from "../../misc/misc";
import {ACTIVE_SEVER} from "../../config";
import useReferralData from "../../misc/hooks/useReferralData";


export default function SelectServer() {
    const {setChangeToggle} = useContext(TogglerContext);
    const {resetReferralData} = useReferralData()
    const [server, setServer] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})

    const changeServer = (value) => {
        setServer(value)
        resetReferralData()
        setChangeToggle((changeToggle) => (!changeToggle))
    }

    return (
        <Menu shadow="md">
            <Menu.Target>
                <Button>Server: {getServerName(server)}</Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={() => changeServer('endless')}>Remastered x6 Endless [OLD]</Menu.Item>
                <Menu.Item onClick={() => changeServer('remastered-x50')}>Remastered x50 Neonate</Menu.Item>
                <Menu.Item onClick={() => changeServer('neonate-x3')}>Remastered x3 Neonate [NEW]</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}