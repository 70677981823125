import {CodeDeliveryFailureException, InvalidEmailRoleAccessPolicyException, InvalidSmsRoleAccessPolicyException, LimitExceededException, TooManyRequestsException} from "../Locale/Errors";


export function ErrorResendCode(error) {
    switch (error.type) {
        case 'CodeDeliveryFailureException':
            return CodeDeliveryFailureException();
        case 'InvalidEmailRoleAccessPolicyException':
            return InvalidEmailRoleAccessPolicyException();
        case 'InvalidSmsRoleAccessPolicyException':
            return InvalidSmsRoleAccessPolicyException();
        case 'LimitExceededException':
            return LimitExceededException();
        case 'TooManyRequestsException':
            return TooManyRequestsException();
        default:
            return error.message;
    }
}