import {Center, Drawer, Navbar, Title} from "@mantine/core";
import {Diamond, Home2, Settings, Shield} from "tabler-icons-react";
import {Link} from "react-router-dom";
import NewLink from "../components/NewLink";
import {getCabinetText} from "../../Locale/Cabinet";
import {useLocalStorage, useViewportSize} from "@mantine/hooks";
import {useContext, useEffect, useState} from "react";
import classicBG from "../../parallaxClassic.jpg"
import interludeBG from "../../parallaxInterlude.jpg"
import {ACTIVE_SEVER} from "../../config";
import {getTextNew} from "../../Locale/Enter";
import {AccountContext} from "../../misc/Accounts";


export default function Sidebar(props) {
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
    const {width} = useViewportSize();
    const {getSession} = useContext(AccountContext);
    const [role, setRole] = useState('');

    useEffect(() => {
        getSession().then(async data => {
            setRole(data['custom:role'])
        })
    }, [getSession, role]);

    const sideButtons = () => {
        return (
            <ul style={{padding: 0}}>
                <NewLink matomo_id={'c_button_sidebar_game_accounts'} to="/" icon={<Home2 size={18}/>} setOpened={props.setOpened}>
                    {getCabinetText('gameAccounts')}
                </NewLink>
                <NewLink matomo_id={'c_button_sidebar_donate'} to="/donate" icon={<Diamond size={18}/>} setOpened={props.setOpened}>
                    {getCabinetText('donate')}
                </NewLink>
                <NewLink matomo_id={'c_button_sidebar_game_settings'} to="/settings" icon={<Settings size={18}/>} setOpened={props.setOpened}>
                    {getCabinetText('settings')}
                </NewLink>
                <NewLink matomo_id={'c_button_sidebar_referral'} to="/referral" icon={<Settings size={18}/>} setOpened={props.setOpened}>
                    {getCabinetText('referral')}
                </NewLink>
                {role === 'admin' && <NewLink matomo_id={'c_button_sidebar_admin'} to="/admin" icon={<Shield size={18}/>} setOpened={props.setOpened}>
                    {getTextNew('Admin')}
                </NewLink>}
            </ul>
        )
    }

    return (
        <>
            {props.opened &&
                <>
                    {width >= 1000 ?
                        <Navbar p="md" hiddenBreakpoint="sm" hidden={!props.opened} width={{base: 300}}
                                style={server === 'classic' ? {backgroundImage: `url(${classicBG})`, backgroundPosition: '-80%'} : {
                                    backgroundImage: `url(${interludeBG})`,
                                    backgroundPosition: '-70%'
                                }}>
                            <Navbar.Section>
                                <Center><Title order={1} component={Link} to={'/'} className="header-logo" style={{color: '#fff', margin: 5}}>Dares</Title></Center>
                            </Navbar.Section>

                            <Navbar.Section grow mt="xl">
                                {sideButtons()}
                            </Navbar.Section>
                        </Navbar>
                        :
                        <Drawer
                            opened={props.opened}
                            onClose={() => props.setOpened(false)}
                            title={<Center><Title order={1} component={Link} to={'/'} className="header-logo" style={{color: '#fff', margin: 5}}>Dares</Title></Center>}
                            styles={{drawer: {backgroundColor: "#0e0e0e"}}}
                        >
                            {sideButtons()}
                        </Drawer>
                    }
                </>
            }
        </>
    );
}