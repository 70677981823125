import axios from "axios";
import {API_URL} from "../../../config";
import {AccountContext} from "../../../misc/Accounts";
import {useContext, useEffect, useState} from "react";
import {LoadingOverlay, Paper, ScrollArea, Table, Title} from "@mantine/core";
import {getCabinetText} from "../../../Locale/Cabinet";
import {TogglerContext} from "../../../App";
import {ShowFailureNotification} from "../../../misc/design/Notifications";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";


export default function DonateHistory() {
    const {changeToggle} = useContext(TogglerContext);
    const {getSession} = useContext(AccountContext)
    const [loading, setLoading] = useState(true)
    const [paymentsData, setPaymentsData] = useState(null)

    useEffect(() => {
        getSession().then(async ({headers}) => {
            axios.post(`${API_URL}/payment/list`, {}, {headers}).then((response) => {
                let total = 0
                const payments = response.data.message.map((paymentData) => {
                    paymentData.intent === 'add' ? total = total + paymentData.amount : total = total - paymentData.amount
                    return {
                        id: paymentData.id,
                        created_at: paymentData.created_at,
                        amount: paymentData.amount,
                        intent: paymentData.intent,
                        total: total,
                    }
                })
                setPaymentsData(payments)
                setLoading(false)
            }).catch(err => {
                ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                console.error(err.response.data.message)
            })
        })
    }, [getSession, changeToggle])

    return (
        <Paper sx={{maxWidth: 500, minWidth: 325, minHeight: 100, position: 'relative'}}>
            <LoadingOverlay visible={loading} overlayBlur={2}/>
            {paymentsData !== null && (paymentsData.length === 0 ? <Title order={5} align="center">{getCabinetText('emptyTransactionsList')}</Title> :
                    <ScrollArea style={{height: 450}}>
                        <Table>
                            <thead>
                            <tr>
                                <th>{getCabinetText('date')}</th>
                                <th>{getCabinetText('amount')}</th>
                                <th>{getCabinetText('balance')}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {paymentsData.slice(0).reverse().map((paymentData) => (
                                <tr key={paymentData.id} style={paymentData.intent === 'add' ? {color: '#e08821'} : {color: '#0e0e0e'}}>
                                    <td>{new Date(Date.parse(paymentData.created_at)).toLocaleDateString()} {new Date(Date.parse(paymentData.created_at)).toLocaleTimeString()}</td>
                                    <td>{paymentData.intent === 'add' ? '+' : '-'} {paymentData.amount}</td>
                                    <td>{paymentData.total}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </ScrollArea>
            )}
        </Paper>
    )
}