import {Button, useMantineTheme} from "@mantine/core";
import {useState} from "react";
import {ErrorResendCode} from "../../Errors/ErrorResendCode";
import {ShowFailureNotification, ShowSuccessNotification} from "../../misc/design/Notifications";
import {getUser} from "../../misc/getUser";
import {getEnterText} from "../../Locale/Enter";
import {Refresh} from "tabler-icons-react";
import Countdown from "react-countdown";


export default function ResendCode(props) {
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [restartKey, setRestartKey] = useState(1)

    const renderer = ({seconds, completed}) => {
        if (completed) {
            return <Button id={props.matomo_id} variant="subtle" color="subtle" leftIcon={<Refresh size={18}/>} loading={loading} onClick={resendCode}>{props.children}</Button>;
        } else {
            return (
                <div className="nk-countdown">
                    <div style={{color: theme.colors.gray[5]}}>
                        {getEnterText('resendCodeIn')}: <span>{seconds}</span>
                    </div>
                </div>
            )
        }
    };

    const resendCode = () => {
        setLoading(true);

        getUser(props.email).resendConfirmationCode((err, result) => {
            if (err) {
                setLoading(false);
                ShowFailureNotification(ErrorResendCode(err));
            } else {
                props.setDate(Date.now())
                setLoading(false);
                ShowSuccessNotification(getEnterText('successResendCode'));
                setRestartKey(restartKey + 1)
            }
        });
    }

    return (
        <Countdown
            date={props.date + 30000}
            renderer={renderer}
            key={restartKey}
        />
    );
}