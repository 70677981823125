import {AliasExistsException, CodeMismatchException, ExpiredCodeException, LimitExceededException, TooManyFailedAttemptsException, TooManyRequestsException} from "../Locale/Errors";


export function ErrorConfirmRegistration(error) {
    switch (error.type) {
        case 'AliasExistsException':
            return AliasExistsException();
        case 'CodeMismatchException':
            return CodeMismatchException();
        case 'ExpiredCodeException':
            return ExpiredCodeException();
        case 'LimitExceededException':
            return LimitExceededException();
        case 'TooManyFailedAttemptsException':
            return TooManyFailedAttemptsException();
        case 'TooManyRequestsException':
            return TooManyRequestsException();
        default:
            return error.message;
    }
}