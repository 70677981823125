const translation = {
    rating: {
        ru: 'Рейтинг',
        en: 'Rating',
    },
    shop: {
        ru: 'Магазин',
        en: 'Shop',
    },
    donate: {
        ru: 'Пожертвования',
        en: 'Donations',
    },
    login: {
        ru: 'Войти',
        en: 'Log in'
    },
    registration: {
        ru: 'Регистрация',
        en: 'Registration'
    },
    signUp: {
        ru: 'Регистрации нового мастер аккаунта',
        en: 'Register a new master account'
    },
    signIn: {
        ru: 'Авторизация в панель управления',
        en: 'Sign in to the control panel'
    },
    welcome: {
        ru: 'Добро пожаловать в панель управления',
        en: 'Welcome to the control panel'
    },
    resetPassword: {
        ru: 'Восстановить пароль',
        en: 'Reset password'
    },
    rememberMe: {
        ru: 'Запомнить меня',
        en: 'Remember me'
    },
    password: {
        ru: 'Пароль',
        en: 'Password'
    },
    passwordDescription: {
        ru: 'Пароль должен состоять не менее чем из 8 символов и содержать хотя бы одну букву.',
        en: 'The password must be at least 8 characters long and contain at least one letter.'
    },
    emailDescription: {
        ru: 'На почту вам будет отправлено письмо с подтверждением о восстановление пароля!',
        en: 'An email will be sent to your email with a confirmation of the password recovery!'
    },
    agree: {
        ru: 'Я согласен c ',
        en: 'I agree to '
    },
    rules: {
        ru: 'правилами',
        en: 'the rules'
    },
    agreeToCommunication: {
        ru: 'Я согласен на получение бонусных промо-кодов и полезной информации про сервер',
        en: 'I agree to receive bonus promo codes and useful information about the server'
    },
    register: {
        ru: 'Зарегистрироваться',
        en: 'Register'
    },
    successConfirmSignUp: {
        ru: 'Подтверждение регистрации прошло успешно!',
        en: 'Registration confirmation was successful!'
    },
    readRules: {
        ru: 'Ознакомится с правилами',
        en: 'Read the rules'
    },
    authorize: {
        ru: 'Авторизоваться',
        en: 'Authorize'
    },
    resendCode: {
        ru: 'Повторно отправить код',
        en: 'Resend code'
    },
    resendCodeIn: {
        ru: 'Потворная отправка через',
        en: 'Resend code in'
    },
    successResendCode: {
        ru: 'Письмо с кодом подтверждения отправлено на почту',
        en: 'A letter with a confirmation code has been sent to your email'
    },
    confirm: {
        ru: 'Подтвердить',
        en: 'Confirm'
    },
    code: {
        ru: 'Код',
        en: 'Code'
    },
    codeSended: {
        ru: 'На указанный Вами почтовый адрес направлено письмо с кодом для верификации.',
        en: 'A letter with a code has been sent to the specified email address.'
    },
    oldPassword: {
        ru: 'Старый пароль',
        en: 'Old password',
    },
    newPassword: {
        ru: 'Новый пароль',
        en: 'New password'
    },
    confirmPassword: {
        ru: 'Подтвердите пароль',
        en: 'Confirm password'
    },
    cookieTitle: {
        ru: 'Файлы cookie на сайте Dares',
        en: 'Cookies on the Dares'
    },
    cookieDescription: {
        ru: 'Мы используем файлы cookie и другие данные по ряду причин, таких как обеспечение надежности и безопасности сайта Dares, персонализация контента и рекламы, предоставление функций социальных сетей и анализ того, как используются наши сайты.',
        en: 'We use cookies and other data for a number of reasons, such as keeping Dares Site reliable and secure, personalising content and ads, providing social media features and to analyse how our Sites are used.'
    },
    cookieError: {
        ru: 'Принять куки',
        en: 'Accept cookie'
    },
    acceptContinue: {
        ru: 'Принять и продолжить',
        en: 'Accept & Continue'
    },
    manageCookies: {
        ru: 'Управление файлами cookie',
        en: 'Manage Cookies'
    },
    referralTitle: {
        ru: 'Реферальная ссылка',
        en: 'Referral key'
    },
}

const newTranslation = {
    ru: {
        'Failed to send the code.': 'Не удалось отправить код.',
        'Invalid email role access policy.': 'Неверная политика доступа по почте.',
        'Password did not conform with policy: Password must have numeric characters': 'Пароль не соответствует требованиям: Пароль должен содержать цифры',
        'Invalid SMS role access policy.': 'Неверная политика доступа по SMS.',
        'Too many requests.': 'Слишком много запросов.',
        'Username already exists.': 'Имя пользователя уже зарегистрировано.',
        'This email is not valid for registration.': 'Данная почта не подходит для регистрации.',
        'Unknown error.': 'Неизвестная ошибка.',
        'The password must be at least 8 characters long and contain at least one letter.': 'Пароль должен содержать не менее 8 символов и хотя бы одну букву.',
        'The password must be at least 4 characters long and contain at least one letter.': 'Пароль должен содержать не менее 4 символов и хотя бы одну букву.',
        'Invalid password.': 'Неверный пароль.',
        'Limit exceeded.': 'Превышен лимит.',
        'User not confirmed.': 'Почта не подтверждена.',
        'Password reset required.': 'Необходимо сбросить пароль.',
        'Incorrect username or password.': 'Неверное имя пользователя или пароль.',
        'Attempt limit exceeded, please try after some time.': 'Превышен лимит попыток. Повторите попытку через некоторое время.',
        'The code you entered does not match': 'Неверный код',
        'The code has expired.': 'The code has expired.',
        'Too many failed attempts.': 'Превышен лимит попыток.',
        'Email already exists.': 'Электронная почта уже существует.',
        'Invalid email': 'Неверная почта',
        'Password does not match requirements': 'Пароль не соответствует требованиям',
        'Passwords do not match': 'Пароли не совпадают',
        'Invalid code': 'Неверный код',
        'Invalid login': 'Неверный логин',
        'Character with this login already exists': 'Персонаж с этим логином уже существует',
        'Incorrect data': 'Неверные данные',
        'Insufficient account balance': 'Недостаточный баланс',
        'Password must be between 8 and 16 characters long.': 'Пароль должен содержать от 8 до 16 символов.',
        'Password must contain at least one digit (0-9).': 'Пароль должен содержать хотя бы одну цифру (0-9).',
        'Password must contain at least one lowercase letter (a-z).': 'Пароль должен содержать хотя бы одну строчную букву (a-z).',
        'Password must include at least one uppercase letter (A-Z).': 'Пароль должен содержать хотя бы одну заглавную букву (A-Z).',
        'Something went wrong. Please contact support': 'Что-то пошло не так. Пожалуйста, свяжитесь с поддержкой',
        'You have successfully unsubscribed': 'Вы успешно отписались',
    }
}

export function getEnterText(str) {
    return translation[str][localStorage.getItem('lang')]
}

export function getTextNew(str) {
    if (localStorage.getItem('lang') === 'en' || localStorage.getItem('lang') === null) {
        return str
    }
    const translated = newTranslation[localStorage.getItem('lang')][str]
    if (translated) {
        return translated
    }
    // TODO: log notification with str
    return str
}