import {getLang} from "../EnterPage/components/SelectLanguage";


export const ErrorTypeToMessageObj = {
    'UnknownError': 'Unknown error.',
    'InvalidPasswordException': 'Invalid password.',
    'LimitExceededException': 'Limit exceeded.',
    'PasswordResetRequiredException': 'Password reset required.',
    'TooManyRequestsException': 'Too many requests.',
    'UserNotConfirmedException': 'User not confirmed.',
    'CodeMismatchException': 'The code you entered does not match.',
    'ExpiredCodeException': 'The code has expired.',
    'TooManyFailedAttemptsException': 'Too many failed attempts.',
    'AliasExistsException': 'Email already exists.',
    'CodeDeliveryFailureException': 'Failed to send the code.',
    'InvalidEmailRoleAccessPolicyException': 'Invalid email role access policy.',
    'InvalidSmsRoleAccessPolicyException': 'Invalid SMS role access policy.',
    'UsernameExistsException': 'Username already exists.',
    'DisposableEmailException': 'This email is not valid for registration.',
    'NotAuthorizedException': 'Incorrect username or password.',
    'ErrorValidateEmail': 'Invalid email',
    'ErrorValidatePassword': 'Password does not match requirements',
    'ErrorValidateConfirmPassword': 'Passwords do not match',
    'ErrorValidateConfirmationCode': 'Invalid code',
    'ErrorValidateLogin': 'Invalid login',
    'E10': 'Character with this login already exists',
    'E4': 'Incorrect data',
    'ErrorIncorrectData': 'Incorrect data',
    'ErrorInsufficientAccountBalance': 'Insufficient account balance'
};


export function UnknownError() {
    switch (getLang()) {
        case 'ru':
            return 'Неизвестная ошибка';
        default:
            return 'Unknown error';
    }
}


export function InvalidPasswordException() {
    switch (getLang()) {
        case 'ru':
            return 'Неверный пароль.';
        default:
            return 'Invalid password.';
    }
}


export function LimitExceededException() {
    switch (getLang()) {
        case 'ru':
            return 'Превышен лимит.';
        default:
            return 'Limit exceeded.';
    }
}


export function PasswordResetRequiredException() {
    switch (getLang()) {
        case 'ru':
            return 'Необходимо сбросить пароль.';
        default:
            return 'Password reset required.';
    }
}


export function TooManyRequestsException() {
    switch (getLang()) {
        case 'ru':
            return 'Слишком много запросов.';
        default:
            return 'Too many requests.';
    }
}


export function UserNotConfirmedException() {
    switch (getLang()) {
        case 'ru':
            return 'Почта не подтверждена.';
        default:
            return 'User not confirmed.';
    }
}

export function CodeMismatchException() {
    switch (getLang()) {
        case 'ru':
            return 'Введенный код не совпадает.';
        default:
            return 'The code you entered does not match.';
    }
}


export function ExpiredCodeException() {
    switch (getLang()) {
        case 'ru':
            return 'Код устарел.';
        default:
            return 'The code has expired.';
    }
}


export function TooManyFailedAttemptsException() {
    switch (getLang()) {
        case 'ru':
            return 'Слишком много неудачных попыток.';
        default:
            return 'Too many failed attempts.';
    }
}


export function AliasExistsException() {
    switch (getLang()) {
        case 'ru':
            return 'Почта уже зарегистрирована.';
        default:
            return 'Email already exists.';
    }
}


export function CodeDeliveryFailureException() {
    switch (getLang()) {
        case 'ru':
            return 'Не удалось отправить код.';
        default:
            return 'Failed to send the code.';
    }
}


export function InvalidEmailRoleAccessPolicyException() {
    switch (getLang()) {
        case 'ru':
            return 'Неверная политика доступа по почте.';
        default:
            return 'Invalid email role access policy.';
    }
}


export function InvalidSmsRoleAccessPolicyException() {
    switch (getLang()) {
        case 'ru':
            return 'Неверная политика доступа по SMS.';
        default:
            return 'Invalid SMS role access policy.';
    }
}

export function NotAuthorizedException() {
    switch (getLang()) {
        case 'ru':
            return 'Неверный логин или пароль';
        default:
            return 'Incorrect username or password.'
    }
}

export function ErrorValidateEmail() {
    switch (getLang()) {
        case 'ru':
            return 'Неверный формат почты';
        default:
            return 'Invalid email';
    }
}

export function ErrorValidatePassword() {
    switch (getLang()) {
        case 'ru':
            return 'Пароль не соответствует требованиям';
        default:
            return 'Password does not match requirements';
    }
}

export function ErrorValidateConfirmPassword() {
    switch (getLang()) {
        case 'ru':
            return 'Пароли не совпадают';
        default:
            return 'Passwords do not match';
    }
}

export function ErrorValidateConfirmationCode() {
    switch (getLang()) {
        case 'ru':
            return 'Код неверный';
        default:
            return 'Invalid code';
    }
}


export function ErrorValidateLogin() {
    switch (getLang()) {
        case 'ru':
            return 'Логин неверный';
        default:
            return 'Invalid login';
    }
}


export function E10() {
    switch (getLang()) {
        case 'ru':
            return 'Персонаж с таким логином уже существует';
        default:
            return 'Character with this login already exists';
    }
}

export function E4() {
    switch (getLang()) {
        case 'ru':
            return 'Неверные данные';
        default:
            return 'Incorrect data';
    }
}


export function ErrorIncorrectData() {
    switch (getLang()) {
        case 'ru':
            return 'Проверьте введенные данные';
        default:
            return 'Incorrect data';
    }
}


export function ErrorInsufficientAccountBalance() {
    switch (getLang()) {
        case 'ru':
            return 'Недостаточно средств';
        default:
            return 'Insufficient account balance';
    }
}