import {
    ErrorTypeToMessageObj,
} from "../Locale/Errors";
import {getTextNew} from "../Locale/Enter";

export function ChangePasswordExceptionHandler(err) {
    const fields_obj = {
        'InvalidPasswordException': 'password',
        'NotAuthorizedException': 'password',
        'LimitExceededException': 'password',
        'PasswordResetRequiredException': 'password',
        'TooManyRequestsException': 'password',
        'UserNotConfirmedException': 'password',
        'UnknownError': 'password',
    }

    // TODO: log notification
    const field = fields_obj[err.name] || 'password'
    return {[field]: getTextNew(ErrorTypeToMessageObj[err.name])}
}