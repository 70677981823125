export const DISCORD_URL = 'https://discord.gg/dares-club';
export let API_URL;
export let DOMAIN_URL;
export let CABINET_URL;
export let COOKIE_DOMAIN;
export let PaypalClientID;
export let UserPoolId;
export let ClientId;

export const ACTIVE_SEVER = 'neonate-x3'
export const SERVER_LIST = [
    'endless',
    'remastered-x50',
    'neonate-x3'
]

export const REMASTERED_DATE_X6 = new Date(Date.UTC(2023, 11, 22, 17)) /// x6 Remastered
export const REMASTERED_DATE_X6_NEW = new Date(Date.UTC(2024, 6, 10, 17)) /// x6 Remastered
export const PROMOTION_DATE_X6 = new Date(Date.UTC(2024, 6, 1)) /// x6 Remastered
export const REMASTERED_DATE_NEONATE_1 = new Date(Date.UTC(2024, 1, 9, 19)) /// x6 Remastered

switch (window.location.href.split('/')[2]) {
    case 'cabinet.dares.club':
        API_URL = 'https://api.dares.club';
        DOMAIN_URL = 'https://dares.club';
        CABINET_URL = 'https://cabinet.dares.club';
        COOKIE_DOMAIN = 'dares.club';
        UserPoolId = 'us-east-1_uglD1vbrL';
        ClientId = '4rhpe3i2lfh46ft042r7nuqh8t';
        PaypalClientID = 'AchOPKdyu_13zZphtkcmJgZkdMSGiA61PmsOu4Taz5j7kjHyUapbs6hvHUBTpEWkOdPLinOJDy_oZIzz';
        break;
    case 'cabinet.dev.dares.club':
        API_URL = 'https://api.dev.dares.club';
        DOMAIN_URL = 'https://dev.dares.club';
        CABINET_URL = 'https://cabinet.dev.dares.club';
        COOKIE_DOMAIN = 'dev.dares.club';
        UserPoolId = 'us-east-1_6rOLK01eA';
        ClientId = '4s5mggk37fic4qsp286tl7v2du';
        PaypalClientID = 'AUmnGTwgjfWM3v7Gun0iduBClQuAV2iGZ_1mWPBUSaYDp8Es2UiqIAJA3a_5OMIMhNL0LAJaA2w-7L79';
        break;
    default:
        API_URL = `https://api.dev.dares.club`;
        DOMAIN_URL = `http://${window.location.href.split('/')[2]}`;
        CABINET_URL = `http://${window.location.href.split('/')[2]}`;
        COOKIE_DOMAIN = 'localhost';
        UserPoolId = 'us-east-1_6rOLK01eA';
        ClientId = '4s5mggk37fic4qsp286tl7v2du';
        PaypalClientID = 'AUmnGTwgjfWM3v7Gun0iduBClQuAV2iGZ_1mWPBUSaYDp8Es2UiqIAJA3a_5OMIMhNL0LAJaA2w-7L79';
}