import {Accordion, ActionIcon, Box, Button, Center, Group, Paper, Stack, Text, Title, Tooltip} from "@mantine/core";
import {Copy, Refresh} from "tabler-icons-react";
import {useContext, useState} from "react";
import axios from "axios";
import {AccountContext} from "../../../misc/Accounts";
import ModalCreateGameAccount from "./ModalCreateGameAccount";
import ModalResetPasswordGameAccount from "./ModalResetPasswordGameAccount";
import {ACTIVE_SEVER, API_URL} from "../../../config";
import {GameAccountsContext} from "../../Cabinet";
import {getCabinetText} from "../../../Locale/Cabinet";
import {useClipboard, useLocalStorage} from "@mantine/hooks";
import {ShowFailureNotification} from "../../../misc/design/Notifications";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";


export default function GameAccounts() {
    const {getSession} = useContext(AccountContext)
    const {gameAccounts, setGameAccounts, setAccountsLoading} = useContext(GameAccountsContext);
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})

    const clipboard = useClipboard({timeout: 500});

    const [tooltipText, setTooltipText] = useState('copy')

    const copyLoginToClipboard = (login) => {
        setTooltipText('copied')
        clipboard.copy(login);

        setInterval(() => {
            setTooltipText('copy')
        }, 3000)
    }

    const refreshGameAccountsHandler = () => {
        setAccountsLoading(true)
        getSession().then(async ({headers}) => {
            axios.get(`${API_URL}/accounts/list/${server}`, {headers})
                .then(({data}) => {
                    setGameAccounts(data.message)
                    setAccountsLoading(false)
                })
                .catch(err => {
                    ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                    console.error(err.response.data.message)
                    setAccountsLoading(false)
                })
        })
    }

    return (
        <Paper sx={{width: 750}}>
            <Stack>
                <Group position="apart">
                    <Title order={4}>{getCabinetText('gameAccountListTitle')}:</Title>
                    <ModalCreateGameAccount refreshAccounts={refreshGameAccountsHandler}/>
                </Group>
                <Box>
                    {gameAccounts.length > 0 &&
                        gameAccounts.map((account, index) => (
                            <Box key={index} sx={{display: 'flex', width: '100%', alignItems: 'flex-start'}}>
                                <Tooltip label={tooltipText} withArrow>
                                    <ActionIcon sx={{paddingTop: 20}}>
                                        <Copy size={18} id={'c_button_copy_login'} onClick={() => copyLoginToClipboard(account.login)}/>
                                    </ActionIcon>
                                </Tooltip>

                                <Accordion chevronPosition="left" sx={{width: '100%'}}>
                                    <Accordion.Item value={account.login}>
                                        <Accordion.Control>{account.login}</Accordion.Control>
                                        <Accordion.Panel>
                                            {!account.characters.length ? <Center><Title order={5} sx={{marginTop: 5, marginBottom: 5}}>No Characters</Title></Center> :
                                                account.characters.map((character, index) => (
                                                    <Title order={5} key={index} style={index % 2 === 0 ? {backgroundColor: '#f8f9fa', marginTop: 5, padding: 3} : {backgroundColor: '#f8f9fa90', marginTop: 5, padding: 3}}>
                                                        {character.char_name}
                                                    </Title>
                                                ))
                                            }
                                            <Group position="center" sx={{marginTop: 10}}>
                                                <ModalResetPasswordGameAccount login={account.login}/>
                                            </Group>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                            </Box>
                        ))
                    }
                    {gameAccounts.length === 0 && <Center><Text size={"xl"} style={{padding: 20}}>{getCabinetText('game_account_empty')}</Text></Center>}
                </Box>
                <Center>
                    <Button id={'c_button_refresh_game_accounts'} variant="outline" color="godlike" radius="xl" leftIcon={<Refresh size={14}/>} onClick={refreshGameAccountsHandler}>{getCabinetText('refreshListGameAccounts')}</Button>
                </Center>
            </Stack>
        </Paper>
    );
}