import {Button} from "@mantine/core";
import {AccountContext} from "../../../misc/Accounts";
import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {ShowFailureNotification} from "../../../misc/design/Notifications";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";


export default function StripePaymentButton(props) {
    const {getSession} = useContext(AccountContext)

    const buyStripe = () => {
        props.setLoading(true)

        getSession().then(async ({headers}) => {
            axios.post(`${API_URL}/payment/stripe/create-checkout-session`, {amount: props.form.values.amount}, {headers}).then((response) => {
                if (response.data.message.url) {
                    window.location.href = response.data.message.url
                }
            }).catch(err => {
                ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                console.error(err.response.data.message)
            })
        })
    }

    return (
        <Button id={'c_button_pay_by_stripe'} sx={{width: '50%'}} onClick={() => buyStripe()}>Stripe</Button>
    )
}