const translation = {
    create_game_account: {
        ru: 'Создать игровой аккаунт',
        en: 'Create a game account',
    },
    prefix: {
        ru: 'Префикс',
        en: 'Prefix',
    },
    login: {
        ru: 'Логин',
        en: 'Login',
    },
    loginDescription: {
        ru: 'Логин должен состоять как минимум из 6 симоволов, но не более 13 и может содержать только латинские буквы и цифры.',
        en: 'Login must be at least 6 characters, but not more than 13 and can only contain Latin letters and numbers.',
    },
    create: {
        ru: 'Создать',
        en: 'Create',
    },
    game_account_created: {
        ru: 'Игровой аккаунт создан',
        en: 'Game account created',
    },
    resetPassword: {
        ru: 'Сменить пароль',
        en: 'Reset password',
    },
    reset_password_game_account: {
        ru: 'Сменить пароль игрового аккаунта',
        en: 'Reset password of the game account',
    },
    game_account_password_reset: {
        ru: 'Пароль игрового аккаунта изменен',
        en: 'Game account password reset',
    },
    game_account_empty: {
        ru: 'У вас нет игровых аккаунтов',
        en: 'You have no game accounts',
    },
    changePassword: {
        ru: 'Изменить пароль',
        en: 'Change password'
    },
    logout: {
        ru: 'Выйти',
        en: 'Logout'
    },
    gameAccountListTitle: {
        ru: 'Ваш список игровых аккаунтов на сервере',
        en: 'Your game accounts list on the server'
    },
    refreshListGameAccounts: {
        ru: 'Обновить список персонажей',
        en: 'Refresh list of characters'
    },
    changePasswordAccountTitle: {
        ru: 'Изменить пароль мастер аккаунта',
        en: 'Change password of the master account'
    },
    gameAccounts: {
        ru: 'Игровые аккаунты',
        en: 'Game Accounts'
    },
    settings: {
        ru: 'Настройки',
        en: 'Settings'
    },
    sessions: {
        ru: 'Сессии',
        en: 'Sessions'
    },
    topPVP: {
        ru: 'Топ ПВП',
        en: 'Top PVP'
    },
    topPK: {
        ru: 'Топ ПК',
        en: 'Top PK'
    },
    topEXP: {
        ru: 'Топ EXP',
        en: 'Top EXP'
    },
    topClan: {
        ru: 'Топ Клан',
        en: 'Top Clan'
    },
    topClanPVP: {
        ru: 'Топ Клан ПВП',
        en: 'Top Clan PVP'
    },
    donate: {
        ru: 'Пожертвования',
        en: 'Donations',
    },
    toPay: {
        ru: 'Пополнить',
        en: 'To pay'
    },
    choosePayments: {
        ru: 'Выберите платежную систему',
        en: 'Choose a payment system'
    },
    balance: {
        ru: 'Баланс',
        en: 'Balance'
    },
    date: {
        ru: 'Дата',
        en: 'Date'
    },
    amount: {
        ru: 'Количество',
        en: 'Amount'
    },
    bonus: {
        ru: 'Бонус',
        en: 'Bonus'
    },
    total: {
        ru: 'Всего',
        en: 'Total'
    },
    device: {
        ru: 'Устройство',
        en: 'Device'
    },
    waitingConfirm: {
        ru: 'Ожидание подтверждения транзакции',
        en: 'Waiting for transaction confirmation'
    },
    ifNothing5: {
        ru: 'Если в течении 5 минут ничего не произойдет, обратитесь к админу в нашем Дискорд канале',
        en: 'If nothing happens within 5 minutes, please contact admin in our Discord channel'
    },
    withdraw: {
        ru: 'Перевести',
        en: 'Withdraw'
    },
    withdrawTitle: {
        ru: 'Вывод Dares Coins в игру',
        en: 'Withdrawing Dares Coins into the game'
    },
    withdrawTitleError: {
        ru: 'Создайте персонажа <br/> для вывода Dares Coins в <br/>',
        en: 'Create a character <br/> to transfer Dares Coins into the <br/>'
    },
    choose: {
        ru: 'Выберите',
        en: 'Choose'
    },
    smallAccount: {
        ru: 'аккаунт',
        en: 'account'
    },
    smallCharacter: {
        ru: 'персонажа',
        en: 'character'
    },
    pickOne: {
        ru: 'Выберите один',
        en: 'Pick one'
    },
    enterAmountDC: {
        ru: 'Введите сумму DC',
        en: 'Enter amount DC'
    },
    emptyTransactionsList: {
        ru: 'Список транзакций пуст',
        en: 'Transaction list is empty'
    },
    successDonate: {
        ru: 'В течении 5 минут вам будет начислено',
        en: 'Within 5 minutes you will receive'
    },
    successWithdraw: {
        ru: 'Dares Coins были успешно переведены. Ваш баланс будет обновлен в течение нескольких минут',
        en: 'Dares Coins have been successfully transferred. Your balance will update within few minutes'
    },
    ok: {
        ru: 'Ок',
        en: 'Ok'
    },
    somethingError: {
        ru: 'Что-то пошло не так',
        en: 'Something was wrong'
    },
    obt: {
        ru: 'Появится после окончания ОБТ',
        en: 'Will appear after the end of OBT'
    },
    referral: {
        ru: 'Рефералка',
        en: 'Referral'
    },
    serverDonateAlert: {
        ru: 'Перевод будет выполнен на сервер:',
        en: 'Funds will be transferred to:'
    },
    paymentError: {
        ru: '*Если при оплате возникла ошибка, пожалуйста, отключите блокировщик рекламы и попробуйте снова',
        en: '*If a payment error occurs, please disable your ad blocker and try again'
    },
    freekassaMinimumAmount: {
        ru: 'Минимальная сумма для оплаты: 11 Dares Coins',
        en: 'Minimum payment amount: 11 Dares Coins'
    },
    daresCoinsDonate: {
        ru: 'Dares Coins Donate',
        en: 'Dares Coins Donate'
    }
}


export function getCabinetText(str) {
    return translation[str][localStorage.getItem('lang')]
}