import {Box, useMantineTheme} from "@mantine/core";
import EnterHeader from "./EnterHeader";
import {createContext, useState} from "react";


export const HeaderToggleContext = createContext()


export default function EnterPage(props) {
    const theme = useMantineTheme();
    const [changeToggle, setChangeToggle] = useState(true)

    return (
        <HeaderToggleContext.Provider value={{changeToggle, setChangeToggle}}>
            <Box sx={{backgroundColor: theme.colors.gray[0], position: "absolute", height: '100%', width: '100%'}}>
                <EnterHeader/>
                {props.children}
            </Box>
        </HeaderToggleContext.Provider>
    );
}