import {Center, ScrollArea, Tabs} from "@mantine/core";
import MainSettings from "../components/Settings/MainSettings";
import Devices from "../components/Settings/Devices";
import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../misc/Accounts";
import Loading from "../../components/Loading";
import {getCabinetText} from "../../Locale/Cabinet";
import {useViewportSize} from "@mantine/hooks";
import {TogglerContext} from "../../App";


export default function Settings() {
    const {changeToggle} = useContext(TogglerContext);
    const {getSession} = useContext(AccountContext);

    const {width} = useViewportSize();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [changeLocalToggle, setChangeLocalToggle] = useState(false);

    useEffect(() => {}, [changeToggle])

    useEffect(() => {
        getSession()
            .then(({user}) => {
                user.listDevices(60, '', {
                    onSuccess: data => {
                        setDevices(data)
                        setLoading(false)
                    },
                    onFailure: err => {
                        console.error(err)
                    }
                })
            })
            .catch((err) => {
                console.error(err)
            })
    }, [getSession, changeLocalToggle])

    return (
        <Center>
            <Loading loading={loading}>
                <Tabs defaultValue="settings" sx={width > 750 ? {width: 750}: {width: '100%'}}>
                    <Tabs.List>
                        <Tabs.Tab value="settings">{getCabinetText('settings')}</Tabs.Tab>
                        <Tabs.Tab value="sessions">{getCabinetText('sessions')}</Tabs.Tab>
                    </Tabs.List>
                    <ScrollArea style={{width: '100%'}}>
                        <Tabs.Panel value="settings">
                            <MainSettings/>
                        </Tabs.Panel>

                        <Tabs.Panel value="sessions">
                            <Devices devices={devices} setChangeToggle={setChangeLocalToggle}/>
                        </Tabs.Panel>
                    </ScrollArea>
                    {/*<Tabs.Tab label="Логи действий">Messages tab content</Tabs.Tab>*/}
                </Tabs>
            </Loading>
        </Center>
    );
}
