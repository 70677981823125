import {Button, Card, Center, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import {Login, Refresh, User} from "tabler-icons-react";
import {useContext, useEffect, useRef, useState} from "react";
import {AccountContext} from "../misc/Accounts";
import {useForm} from "@mantine/form";
import {ErrorConfirmPassword} from "../Errors/ErrorConfirmPassword";
import {ErrorForgotPassword} from "../Errors/ErrorForgotPassword";
import GrayButton from "./components/GrayButton";
import SelectLanguage from "./components/SelectLanguage";
import {getEnterText} from "../Locale/Enter";
import {HeaderToggleContext} from "./EnterPage";
import {ShowFailureNotification, ShowSuccessNotification} from "../misc/design/Notifications";
import {ReminderConfirmForm, ReminderSendCodeForm} from "../forms/enterForms";
import {getUser} from "../misc/getUser";
import CodeInput from "../components/CodeInput";
import EmailInput from "../components/EmailInput";
import PassInput from "../components/PassInput";
import Welcome from "./components/Welcome";
import {isEmpty} from "../misc/misc";


export default function Reminder() {
    const {changeToggle} = useContext(HeaderToggleContext);
    const prevChangeToggle = useRef();

    const theme = useMantineTheme();
    const {authenticate} = useContext(AccountContext)

    const [loader, setLoader] = useState(false)
    const [stage, setStage] = useState(1);

    const formSendCode = useForm(ReminderSendCodeForm);
    const formConfirm = useForm(ReminderConfirmForm);

    useEffect(() => {
        if (prevChangeToggle.current !== changeToggle) {
            if (!isEmpty(formSendCode.errors)) {
                formSendCode.validate();
            }
            if (!isEmpty(formConfirm.errors)) {
                formConfirm.validate();
            }

            prevChangeToggle.current = changeToggle;
        }
    }, [changeToggle, formConfirm, formSendCode])


    const sendCode = () => {
        setLoader(true)
        getUser(formSendCode.values.email).forgotPassword({
            onSuccess: () => {
                setLoader(false)
            },
            onFailure: err => {
                ShowFailureNotification(ErrorForgotPassword(err))
                formSendCode.setErrors({email: ErrorForgotPassword(err)})
                setLoader(false)
            },
            inputVerificationCode: () => {
                setStage(2);
                setLoader(false)
            }
        })
    }

    const resetPassword = values => {
        getUser(formSendCode.values.email).confirmPassword(values.confirmationCode, values.password, {
            onSuccess: () => {
                ShowSuccessNotification('Пароль успешно изменен');
                authenticate(formSendCode.values.email.toLowerCase(), values.password)
                    .then(() => {
                        setInterval(() => {
                            window.location.href = '/'
                        }, 500)
                        setLoader(false)
                    })
                    .catch(err => {
                        //TODO: log
                        console.error('Failed to login!', err);
                        setLoader(false)
                    })
            },
            onFailure: err => {
                ShowFailureNotification(ErrorConfirmPassword(err))
                formConfirm.setErrors({confirmationCode: ErrorConfirmPassword(err)})
                setLoader(false)
            }
        })
    };

    return (
        <Center>
            <Stack>
                <Welcome/>
                <Card>
                    <Card.Section sx={{background: theme.fn.linearGradient(120, 'rgb(155,98,19)', 'rgba(224, 136, 33, 1)'), padding: 10}}>
                        <Group position="apart">
                            <Text size="sm" sx={{color: theme.colors.gray[0]}}>{getEnterText('signIn')}</Text>
                            <SelectLanguage/>
                        </Group>
                    </Card.Section>
                    {stage === 1 &&
                        <form id={'c_form_reset_password_step_1'} onSubmit={formSendCode.onSubmit(sendCode)}>
                            <Card.Section>
                                <Stack>
                                    <EmailInput matomo_id={'c_input_email'} form={formSendCode} description/>
                                </Stack>
                            </Card.Section>

                            <Card.Section>
                                <Group position="center">
                                    <Button id={'c_button_submit_reset_password_step_1'} type="submit" leftIcon={<Refresh size={18}/>}
                                            loading={loader}>{getEnterText('resetPassword')}</Button>
                                </Group>
                            </Card.Section>
                        </form>
                    }
                    {stage === 2 &&
                        <form id={'c_form_reset_password_step_2'} onSubmit={formConfirm.onSubmit((values) => resetPassword(values))}>
                            <Card.Section>
                                <Stack>
                                    <Center><Text align="center">{getEnterText('codeSended')}</Text></Center>
                                    <CodeInput matomo_id={'c_input_code'} form={formConfirm}/>
                                    <PassInput matomo_id={'c_input_password'} form={formConfirm} description/>
                                    <PassInput matomo_id={'c_input_confirm_password'} form={formConfirm} field="confirmPassword"/>
                                </Stack>
                            </Card.Section>

                            <Card.Section>
                                <Group position="right">
                                    <Button id={'c_button_submit_reset_password_step_2'} type="submit" leftIcon={<Login size={18}/>}
                                            loading={loader}>{getEnterText('confirm')}</Button>
                                </Group>
                            </Card.Section>
                        </form>
                    }
                    <Card.Section sx={{backgroundColor: theme.colors.gray[1]}}>
                        <Group position="center">
                            <GrayButton matomo_id={'c_button_login'} to="/login" leftIcon={<User size={18}/>}>
                                {getEnterText('login')}
                            </GrayButton>
                        </Group>
                    </Card.Section>
                </Card>
            </Stack>
        </Center>
    );
}