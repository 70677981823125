import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useLocalStorage} from "@mantine/hooks";
import {ACTIVE_SEVER, API_URL} from "../../../config";
import axios from "axios";
import {ShowFailureNotification, ShowSuccessNotification} from "../../../misc/design/Notifications";
import {AccountContext} from "../../../misc/Accounts";
import {ActionIcon, Center, CopyButton, Group, Pagination, Paper, Select, Stack, Table, Title} from "@mantine/core";
import {Copy, CopyOff} from "tabler-icons-react";


const Promocodes = ({itemNames, itemLoading, promocodesUpdateTrigger}) => {
    const {getSession} = useContext(AccountContext);
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
    const [limit, setLimit] = useLocalStorage({key: 'limit', defaultValue: 20})
    const [promocodes, setPromocodes] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState('createdAt')
    const [sortOrder, setSortOrder] = useState('desc')
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const fetchPromocodes = useCallback(() => {
        const serv = localStorage.getItem('server').replace(/"/g, '')
        setLoading(true)
        let localLimit = localStorage.getItem('limit') ? localStorage.getItem('limit').replace(/"/g, '') : 20;

        getSession().then(async ({headers}) => {
            axios.get(`${API_URL}/promocodes/${serv}?limit=${localLimit}&order_by=${sortBy}&direction=${sortOrder}&page=${page}`, {headers})
                .then(({data}) => {
                    console.log(data.message.data)
                    setPromocodes(data.message.data)
                    setTotalPages(data.message.pages_count)
                })
                .catch(err => {
                    ShowFailureNotification(err.response.data.message)
                    console.error(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }, [getSession, page, sortBy, sortOrder])

    useEffect(() => {
        fetchPromocodes()
    }, [fetchPromocodes, getSession, server, promocodesUpdateTrigger, limit]);

    const changeSortBy = (value) => {
        if (value === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else {
            setSortBy(value)
            setSortOrder('asc')
        }
    }

    const copyCode = (code) => {
        navigator.clipboard.writeText(code)
        getSession().then(async ({headers}) => {
            axios.patch(`${API_URL}/promocodes`, {code, server}, {headers})
                .then(() => {
                    ShowSuccessNotification('Code was marked as shared')
                    fetchPromocodes()
                })
                .catch(err => {
                    console.error(err)
                    ShowFailureNotification(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const itemsCell = (items) => {
        return <Table striped={false}>
            <tbody>
            {items.split(';').map((item, index) => {
                const itemData = item.split('-')
                return <tr key={index}>
                    <td>{itemNames[itemData[0]] ? itemNames[itemData[0]] : itemData[0]}</td>
                    <td>{itemData[1]}</td>
                    <td>{itemData[2]}</td>
                </tr>
            })}
            </tbody>
        </Table>
    }

    const NonSelectableText = (data) => {
        return <td className="no-select"
                   onCopy={(e) => e.preventDefault()}>{data}</td>
    }

    return (
        <Paper p="xl" sx={{width: '100%', position: 'relative'}}>
            <Stack>
                <Group>
                    <Pagination color={'slider'} page={page} onChange={setPage} total={totalPages}/>
                    <Select value={`${limit}`} onChange={setLimit} data={['10', '20', '50', '100', '200']}></Select>
                </Group>
                {itemLoading || loading || promocodes === undefined ? <Center><Title order={3} mt="md">Loading</Title></Center> :
                    promocodes.length === 0 ? <Center><Title order={3} mt="md">Promocodes not found</Title></Center> :
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>Copy</th>
                                <th onClick={() => changeSortBy('name')} style={{cursor: 'pointer'}}>Code</th>
                                <th onClick={() => changeSortBy('minLevel')} style={{cursor: 'pointer'}}>Min Level</th>
                                <th onClick={() => changeSortBy('maxLevel')} style={{cursor: 'pointer'}}>Max Level</th>
                                <th onClick={() => changeSortBy('items')} style={{cursor: 'pointer'}}>Items</th>
                                <th onClick={() => changeSortBy('premium')} style={{cursor: 'pointer'}}>Premium</th>
                                <th onClick={() => changeSortBy('isShared')} style={{cursor: 'pointer'}}>Shared</th>
                                <th onClick={() => changeSortBy('createdAt')} style={{cursor: 'pointer'}}>Created At</th>
                            </tr>
                            </thead>
                            <tbody>
                            {promocodes.map((promocode, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <CopyButton value={promocode.name}>
                                            {({copied}) => (
                                                <ActionIcon color={'subtle'} onClick={() => copyCode(promocode.name)}>
                                                    {copied ? <CopyOff/> : <Copy/>}
                                                </ActionIcon>
                                            )}
                                        </CopyButton>
                                    </td>
                                    {NonSelectableText(promocode.name)}
                                    <td>{promocode.minLevel}</td>
                                    <td>{promocode.maxLevel}</td>
                                    <td style={{borderLeft: '1px solid #dee2e6', borderRight: '1px solid #dee2e6'}}>{itemsCell(promocode.items)}</td>
                                    <td>{promocode.premium}</td>
                                    <td>{promocode.isShared}</td>
                                    <td>{new Date(promocode.createdAt * 1000).toLocaleString()}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                }
                <Pagination color={'slider'} page={page} onChange={setPage} total={totalPages}/>
            </Stack>
        </Paper>
    );
};

export default Promocodes;