import {CodeDeliveryFailureException, InvalidEmailRoleAccessPolicyException, InvalidSmsRoleAccessPolicyException, LimitExceededException, TooManyRequestsException, UserNotConfirmedException} from "../Locale/Errors";


export function ErrorForgotPassword(error) {
    switch (error.type) {
        case 'CodeDeliveryFailureException':
            return CodeDeliveryFailureException();
        case 'InvalidEmailRoleAccessPolicyException':
            return InvalidEmailRoleAccessPolicyException();
        case 'InvalidSmsRoleAccessPolicyException':
            return InvalidSmsRoleAccessPolicyException();
        case 'LimitExceededException':
            return LimitExceededException();
        case 'TooManyRequestsException':
            return TooManyRequestsException();
        case 'UserNotConfirmedException':
            return UserNotConfirmedException();
        default:
            return error.message;
    }
}