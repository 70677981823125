import {getEnterText} from "../Locale/Enter";
import {PasswordInput} from "@mantine/core";


export default function PassInput(props) {
    return (
        <PasswordInput
            id={props.matomo_id}
            label={getEnterText(props.field ? props.field : 'password')}
            description={props.description && (typeof props.description === 'string' ? props.description : getEnterText('passwordDescription'))}
            {...props.form.getInputProps(props.field ? props.field : 'password')}
        />
    );
}