import {Navigate, Route, Routes} from "react-router-dom";
import Home from "../Cabinet/pages/Home";
import Settings from "../Cabinet/pages/Settings";
import Cabinet from "../Cabinet/Cabinet";
import Donate from "../Cabinet/pages/Donate";
import Success from "../Cabinet/pages/Success";
import Referral from "../Cabinet/pages/Referral";
import Unsubscribe from "../Cabinet/pages/Unsubscribe";
import AdminPanel from "../Cabinet/pages/AdminPanel";
import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../misc/Accounts";


export default function AuthenticatedRoutes() {
    const {getSession} = useContext(AccountContext);
    const [role, setRole] = useState('');

    useEffect(() => {
        getSession().then(async data => {
            setRole(data['custom:role'])
        })
    }, [getSession, role]);

    return (
        <Cabinet>
            <Routes>
                <Route path="/" element={<Home/>}/>
                {/*<Route path="/rating" element={<Rating/>}/>*/}
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/donate" element={<Donate/>}/>
                <Route path="/referral" element={<Referral/>}/>
                <Route path="/success/:payment" element={<Success/>}/>
                <Route path="/unsubscribe/:cognitoId" element={<Unsubscribe/>}/>
                {role === 'admin' && <Route path="/admin" element={<AdminPanel/>}/>}
                <Route path="/*" element={<Navigate to={`/`}/>}/>
            </Routes>
        </Cabinet>
    );
}