import {
    validateCognitoPassword,
    validateConfirmNewPassword,
    validateConfirmPassword,
    validateGameAccountPassword,
    validateLogin,
    validateNewPassword,
} from "../misc/Validations";
import {ErrorIncorrectData, ErrorInsufficientAccountBalance} from "../Locale/Errors";

export const MainSettingsForm = {
    validateInputOnChange: true,
    initialValues: {
        password: "",
        newPassword: "",
        confirmPassword: ""
    },
    validate: {
        ...validateCognitoPassword,
        ...validateNewPassword,
        ...validateConfirmNewPassword,
    },
}

export const CreateGameAccountForm = (prefixes) => {
    return {
        validateInputOnChange: true,
        initialValues: {
            login: '',
            password: '',
            confirmPassword: '',
            prefix: prefixes[0].value
        },
        validate: {
            ...validateLogin,
            ...validateGameAccountPassword,
            ...validateConfirmPassword
        },
    }
}

export const ResetPasswordGameAccountForm = {
    validateInputOnChange: true,
    initialValues: {
        password: '',
        confirmPassword: ''
    },
    validate: {
        ...validateGameAccountPassword,
        ...validateConfirmPassword
    },
}

export const PaymentAmountForm = {
    initialValues: {
        amount: 5
    },
    validate: {
        amount: value => (Number.isInteger(value) && value >= 1) ? null : ErrorIncorrectData()
    }
}

export const WithdrawForm = (maxAmount) => {
    return {
        initialValues: {
            account: '',
            character: '',
            amount: 1,
        },
        validate: {
            amount: value => (Number.isInteger(value) && value >= 1 && value <= parseInt(maxAmount)) ? null : ErrorInsufficientAccountBalance()
        }
    }
}