import React from 'react';
import {Center, Loader} from '@mantine/core';


export default function Loading(props) {

    return (props.loading ? <Center sx={props.sx}><Loader variant="dots" color="dark" sx={{marginTop: 250}}/></Center> : props.children)
}


