import React, {useContext, useState} from 'react';
import {AccountContext} from "../../../misc/Accounts";
import {useForm} from "@mantine/form";
import axios from "axios";
import {API_URL} from "../../../config";
import {ShowFailureNotification} from "../../../misc/design/Notifications";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";
import {Button, Checkbox, Group, Modal, NumberInput, Space, Stack, Text, TextInput, Title} from "@mantine/core";
import EmailInput from "../../../components/EmailInput";
import {calculateCoins} from "../../../misc/misc";

const AdminDonate = () => {
    const {getSession} = useContext(AccountContext);
    const [opened, setOpened] = useState(false)

    const form = useForm({
        initialValues: {
            email: '',
            coins: 0,
            description: '',
            is_donate: false,
            amount: 0
        },

        validate: {
            description: (value) => (value.length < 5 ? 'Too short description' : null),
            coins: (value) => (value < 1 ? 'Too few coins' : null),
        },
    })

    const setBonusCoins = (value) => {
        if (value === undefined) {
            form.setFieldValue('amount', 0)
            return
        }

        if (!form.values.is_donate) {
            form.setFieldValue('amount', value);
            return;
        }

        const valueInEur = parseInt((value / 10).toFixed(0))

        const coins = calculateCoins(valueInEur)

        if (coins !== form.values.amount) {
            form.setFieldValue('amount', coins);
        }
    }


    const submit = (values) => {
        getSession().then(async ({headers}) => {
            axios.post(`${API_URL}/payment/manual`, values, {headers})
                .then(({data}) => {
                    console.log(data)
                    form.reset()
                })
                .catch(err => {
                    ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                    console.error(err.response.data.message)
                })
        })
    }

    return (
        <>
            <Modal opened={opened} onClose={() => setOpened(false)}>
                <Title order={3}>Send Dares Coins</Title>
                <Space h={20}/>
                <form onSubmit={form.onSubmit(submit)}>
                    <Stack>
                        <Checkbox label={'Donate'} {...form.getInputProps('is_donate')}/>
                        <EmailInput matomo_id={'input_admin_email'} form={form}/>
                        <NumberInput
                            label={'coins'}
                            {...form.getInputProps('coins')}
                            onChange={(value) => {
                                setBonusCoins(value)
                                form.setFieldValue('coins', value ? value : 0)
                            }}
                        />
                        <TextInput label={'description'} {...form.getInputProps('description')}/>
                        <Text>Will send: {form.values.amount}</Text>
                        <Button type="submit">Submit</Button>
                    </Stack>
                </form>
            </Modal>
            <Group position="center">
                <Button onClick={() => setOpened(true)}>Send Dares Coins</Button>
            </Group>
        </>
    );
};

export default AdminDonate;