import {useContext} from "react";
import {AccountContext} from "../../../misc/Accounts";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";
import axios from "axios";
import {API_URL} from "../../../config";
import {ShowFailureNotification} from "../../../misc/design/Notifications";
import {Box, Button, Text} from "@mantine/core";
import {getCabinetText} from "../../../Locale/Cabinet";


export default function FreekassaPaymentButton(props) {
    const {getSession} = useContext(AccountContext)

    const buyFreekassa = () => {
        props.setLoading(true)

        getSession().then(async ({headers}) => {
            axios.post(`${API_URL}/payment/freekassa/create-checkout-session`, {amount: props.form.values.amount}, {headers}).then((res) => {
                if (res.data.message.url) {
                    window.location.href = res.data.message.url
                }
            }).catch(err => {
                ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                console.error(err.response.data.message)
            })
        })
    }

    return (
        <Box style={{width: '50%'}}>
            <Button id={'c_button_pay_by_freekassa'} sx={{width: '100%'}} disabled={props.amount < 11} onClick={() => buyFreekassa()}>Free-kassa</Button>
            <Text>{getCabinetText(props.amount < 11 ? 'freekassaMinimumAmount' : 'paymentError')}</Text>
        </Box>
    )
}