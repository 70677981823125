import {Link} from "react-router-dom";
import {getEnterText} from "../../Locale/Enter";
import {Button} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {HeaderToggleContext} from "../EnterPage";


export default function HeaderButton(props) {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);

    const [variant, setVariant] = useState('subtle');

    useEffect(() => {
        if (window.location.pathname === props.to) {
            setVariant('light')
        } else {
            setVariant('subtle')
        }
    }, [changeToggle, props.to]);

    return (
        <Button
            id={props.matomo_id}
            component={Link}
            to={props.to}
            variant={variant}
            size="xs"
            color="dark"
            leftIcon={props.icon}
            onClick={() => setChangeToggle(!changeToggle)}
        >
            {getEnterText(props.text)}
        </Button>
    );
}