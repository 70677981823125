import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Account} from "./misc/Accounts";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import MantineTheme from "./misc/design/MantineTheme";
import {NotificationsProvider} from "@mantine/notifications";
import {Provider} from "react-redux";
import store from "./misc/rematch/store";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Account>
                <BrowserRouter>
                    <MantineTheme>
                        <NotificationsProvider>

                            <App/>

                        </NotificationsProvider>
                    </MantineTheme>
                </BrowserRouter>
            </Account>
        </Provider>
    </React.StrictMode>
);

