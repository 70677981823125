import {Button, Center, Container, Stack, Title} from "@mantine/core";
import {useForm} from "@mantine/form";
import {DeviceFloppy} from "tabler-icons-react";
import {AccountContext} from "../../../misc/Accounts";
import {useContext, useState} from "react";
import {ChangePasswordExceptionHandler} from "../../../Errors/ErrorChangePassword";
import {ShowSuccessNotification} from "../../../misc/design/Notifications";
import {MainSettingsForm} from "../../../forms/appForms";
import PassInput from "../../../components/PassInput";
import {getCabinetText} from "../../../Locale/Cabinet";


export default function MainSettings() {
    const {getSession, authenticate} = useContext(AccountContext);
    const [changePasswordLoading, setChangePasswordLoading] = useState(false)

    const formChangePassword = useForm(MainSettingsForm);

    const changePassword = () => {
        setChangePasswordLoading(true)

        getSession().then(({user}) => {
            user.changePassword(formChangePassword.values.password, formChangePassword.values.newPassword, (err, result) => {
                if (err) {
                    formChangePassword.setErrors(ChangePasswordExceptionHandler(err))
                    // TODO: notification and logging
                    setChangePasswordLoading(false)
                }
                if (!err) {
                    setChangePasswordLoading(false)
                    formChangePassword.reset();
                    ShowSuccessNotification('Пароль успешно изменен');
                    authenticate(user.username, formChangePassword.values.newPassword)
                }
            })
        });
    };

    return (
        <Container size={"xl"}>
            <form onSubmit={formChangePassword.onSubmit((values) => changePassword(values))}>
                <Container size="sm" sx={{paddingTop: 20}}>
                    <Stack>
                        <Center><Title order={4}>{getCabinetText('changePasswordAccountTitle')}</Title></Center>
                        <PassInput form={formChangePassword}/>
                        <PassInput form={formChangePassword} field="newPassword" description/>
                        <PassInput form={formChangePassword} field="confirmPassword"/>
                        <Center><Button type="submit" variant="filled" color="godlike" loading={changePasswordLoading} leftIcon={<DeviceFloppy size={18}/>} sx={{margin: 20}}>{getCabinetText('changePassword')}</Button></Center>
                    </Stack>
                </Container>
            </form>
        </Container>
    );
};
