import {Link} from "react-router-dom";
import {Button} from "@mantine/core";
import {HeaderToggleContext} from "../EnterPage";
import {useContext} from "react";


function bottomButtonStyle(theme) {
    return {
        root: {
            backgroundColor: theme.colors.gray[1], color: theme.colors.gray[6],

            '&:hover': {
                backgroundColor: theme.colors.gray[1], color: theme.colors.gray[6],

                '&:before': {
                    visibility: 'visible', transform: 'translateY(0)', opacity: 1,
                }
            },

            '&:before': {
                position: 'absolute', bottom: -5, left: 0, width: '100%', height: 2, content: '""', boxSizing: 'border-box', backgroundColor: theme.colors.gray[6], visibility: 'hidden', transform: 'translateY(6px)', opacity: 0, transformOrigin: '50% 50%', transition: '0.12s ease-out'
            }
        }
    }
}


export default function GrayButton(props) {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const link_ref = props.to ? {to: props.to} : {href: props.href};
    return (
        <Button
            id={props.matomo_id}
            component={props.to ? Link : 'a'}
            {...link_ref}
            variant="subtle"
            size="xs"
            compact
            leftIcon={props.leftIcon}
            styles={(theme) => (bottomButtonStyle(theme))}
            onClick={() => setChangeToggle(!changeToggle)}
        >
            {props.children}
        </Button>
    )
}