import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_URL, CABINET_URL, DISCORD_URL} from "../../config";
import {useParams} from "react-router-dom";
import Loading from "../../components/Loading";
import {Button, Center, Image, Paper, Stack, Text} from "@mantine/core";
import successImage from "../../images/success.png";
import failureImage from "../../images/failure.png";
import {getCabinetText} from "../../Locale/Cabinet";
import {getTextNew} from "../../Locale/Enter";

const Unsubscribe = () => {
    const {cognitoId} = useParams()
    //
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(undefined)

    useEffect(() => {
        axios.get(`${API_URL}/accounts/unsubscribe/${cognitoId}`).then(({data}) => {
            if (data.message === 'Success') {
                setSuccess(true)
                setLoading(false)
                return
            }
            setSuccess(false)
            setLoading(false)
        })
    }, [cognitoId])

    return <Loading loading={loading || success === undefined}>
        <Center>
            <Paper p="xl" mt={150} sx={{minWidth: 500, position: 'relative', minHeight: 350}}>
                {success ? <Stack align="center" justify="space-around">
                        <Image
                            radius="md"
                            src={successImage}
                            alt="Success Image"
                            width={200}
                        />
                        <Text size="md">{getTextNew('You have successfully unsubscribed')}</Text>
                        <Button
                            sx={{width: '50%'}}
                            onClick={() => {
                                window.location.href = `${CABINET_URL}/login`
                            }}
                        >
                            {getCabinetText('ok')}
                        </Button>
                    </Stack> :
                    <Stack align="center" justify="space-around">
                        <Image
                            radius="md"
                            src={failureImage}
                            alt="Failure Image"
                            width={200}
                        />
                        <Text size="md">{getTextNew('Something went wrong. Please contact support')}</Text>
                        <Button
                            sx={{width: '50%'}}
                            onClick={() => {
                                window.location.href = `${DISCORD_URL}`
                            }}
                        >
                           Discord
                        </Button>
                    </Stack>}
            </Paper>
        </Center>
    </Loading>
};

export default Unsubscribe;