import {API_URL} from "../../config";
import axios from "axios";


export const referralDataState = {
    state: null,
    reducers: {
        setReferralData(state, payload) {
            return payload
        }
    },
    effects: (dispatch) => ({
        async uploadReferralData({data}) {
            try {
                const res = await axios.post(`${API_URL}/referral/statistic`, {referralKey: data['given_name']}, {headers: data.headers})
                dispatch.referralDataState.setReferralData(res.data.message)
                return res
            } catch (e) {
                throw e
            }
        },
    }),
};