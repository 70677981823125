import {getEnterText} from "../Locale/Enter";
import {TextInput} from "@mantine/core";


export default function CodeInput(props) {

    return (
        <div>
            <TextInput
                id={props.matomo_id}
                label={getEnterText('code')}
                placeholder="123456"
                disabled={props.disabled}
                {...props.form.getInputProps('confirmationCode')}
            />
        </div>
    );
}