import {validateConfirmationCode, validateConfirmPassword, validateEmail, validateCognitoPassword} from "../misc/Validations";
import {getCookieValue} from "../misc/misc";

export const SignInForm = {
    validateInputOnChange: true,
    initialValues: {
        email: '',
        password: '',
        remember: false
    },
    validate: {
        ...validateEmail,
        ...validateCognitoPassword
    },
}

export const SignUpSendCodeForm = {
    validateInputOnChange: true,
    initialValues: {
        email: '',
        password: '',
        confirmPassword: '',
        referral: getCookieValue('referral_key') || '',
        agreeTerms: false,
        agreeToCommunication: false
    },
    validate: {
        ...validateEmail,
        ...validateCognitoPassword,
        ...validateConfirmPassword
    },
}

export const SignUpConfirmForm = (code) => {
    return {
        validateInputOnChange: true,
        initialValues: {
            confirmationCode: code
        },
        validate: {
            ...validateConfirmationCode
        },
    }
}

export const ReminderSendCodeForm = {
    validateInputOnChange: true,
    initialValues: {
        email: ''
    },
    validate: {
        ...validateEmail
    }
}

export const ReminderConfirmForm = {
    validateInputOnChange: true,
    initialValues: {
        confirmationCode: '',
        password: '',
        confirmPassword: ''
    },
    validate: {
        ...validateConfirmationCode,
        ...validateCognitoPassword,
        ...validateConfirmPassword
    },
}