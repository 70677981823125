import {NotAuthorizedException, UnknownError, UserNotConfirmedException} from "../Locale/Errors";


export function ErrorSignIn(type) {
    switch (type) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
            return NotAuthorizedException();
        case  'UserNotConfirmedException':
            return UserNotConfirmedException();
        default:
            return UnknownError();
    }
}