import {ErrorValidateConfirmationCode, ErrorValidateConfirmPassword, ErrorValidateEmail, ErrorValidateLogin, UnknownError} from "../Locale/Errors";
import axios from "axios";
import {ShowFailureNotification} from "./design/Notifications";
import {getTextNew} from "../Locale/Enter";


function emailPattern(value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(value)
}

function loginPattern(login) {
    return /^[a-z\d]{6,13}$/i.test(login)
}

export const validateEmail = {email: value => emailPattern(value) ? null : ErrorValidateEmail()}

function ValidateAndGetErrorCognitoPassword(password) {
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(password)) {
        return null
    }
    const errors = [];
    let errors_count = 0;

    if (password.length < 8 || password.length > 16) {
        errors.push(<p key={1}>{getTextNew('Password must be between 8 and 16 characters long.')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={1} style={{color: 'green'}}>{getTextNew('Password must be between 8 and 16 characters long.')}</p>);
    }

    if (!/\d/.test(password)) {
        errors.push(<p key={2}>{getTextNew('Password must contain at least one digit (0-9).')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={2} style={{color: 'green'}}>{getTextNew('Password must contain at least one digit (0-9).')}</p>);
    }
    if (!/[a-z]/.test(password)) {
        errors.push(<p key={3}>{getTextNew('Password must contain at least one lowercase letter (a-z).')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={3} style={{color: 'green'}}>{getTextNew('Password must contain at least one lowercase letter (a-z).')}</p>);
    }

    if (!/[A-Z]/.test(password)) {
        errors.push(<p key={4}>{getTextNew('Password must include at least one uppercase letter (A-Z).')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={4} style={{color: 'green'}}>{getTextNew('Password must include at least one uppercase letter (A-Z).')}</p>);
    }

    return errors_count === 0 ? null : errors;
}

function ValidateAndGetErrorGameAccountPassword(password) {
    if (/(?=.*[a-z])\w{4,16}/.test(password)) {
        return null
    }

    const errors = [];
    let errors_count = 0;

    if (password.length < 4 || password.length > 16) {
        errors.push(<p key={1}>{getTextNew('Password must be between 4 and 16 characters long.')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={1} style={{color: 'green'}}>{getTextNew('Password must be between 4 and 16 characters long.')}</p>);
    }

    if (!/[a-z]/.test(password)) {
        errors.push(<p key={2}>{getTextNew('Password must contain at least one lowercase letter (a-z).')}</p>);
        errors_count++;
    } else {
        errors.push(<p key={2} style={{color: 'green'}}>{getTextNew('Password must contain at least one lowercase letter (a-z).')}</p>);
    }

    return errors_count === 0 ? null : errors;
}

export const validateCognitoPassword = {password: value => ValidateAndGetErrorCognitoPassword(value)}
export const validateGameAccountPassword = {password: value => ValidateAndGetErrorGameAccountPassword(value)}
export const validateNewPassword = {newPassword: value => ValidateAndGetErrorCognitoPassword(value)}
export const validateConfirmPassword = {confirmPassword: (value, values) => value !== values.password ? ErrorValidateConfirmPassword() : null}
export const validateConfirmNewPassword = {confirmPassword: (value, values) => value !== values.newPassword ? ErrorValidateConfirmPassword() : null}

export const validateConfirmationCode = {confirmationCode: value => value.length === 6 ? null : ErrorValidateConfirmationCode()}

export const validateLogin = {login: value => loginPattern(value) ? null : ErrorValidateLogin()}


export const checkEmailDisposability = (email, errCallback, callback) => {
    axios.get(`https://disposable.debounce.io/?email=${email.toLowerCase()}`).then(res => {
        if (res.data.disposable === 'true') {
            errCallback()
            return;
        }
        callback()
    }).catch(err => {
        console.error(err)
        ShowFailureNotification(UnknownError())
    })
}
