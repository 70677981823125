import {Center, Group, Stack} from "@mantine/core";
import AdminDonate from "../components/Admin/AdminDonate";
import CreatePromocodes from "../components/Admin/CreatePromocodes";
import Promocodes from "../components/Admin/Promocodes";
import {useEffect, useState} from "react";
import axios from "axios";

const AdminPanel = () => {
    const [itemNames, setItemNames] = useState([])
    const [itemLoading, setItemLoading] = useState(true)
    const [promocodesUpdateTrigger, setPromocodesUpdateTrigger] = useState(true)

    useEffect(() => {
        axios.get(`https://new.dares.club/database/items.json`).then(({data}) => {
            setItemNames(Object.keys(data).map(key => {
                const add_name = data[key].add_name ? `- (${data[key].add_name})` : ''
                return `${key} - ${data[key].name} ${add_name}`
            }))
            console.log(data)
        }).finally(() => setItemLoading(false))
    }, []);

    return <Stack>
        <Group>
            <AdminDonate/>
            <CreatePromocodes itemNames={itemNames} itemLoading={itemLoading} setPromocodesUpdateTrigger={setPromocodesUpdateTrigger}/>

        </Group>
        <Center>
            <Promocodes itemNames={itemNames} itemLoading={itemLoading} promocodesUpdateTrigger={promocodesUpdateTrigger}/>
        </Center>
    </Stack>
};

export default AdminPanel;