import {HeaderToggleContext} from "../EnterPage";
import {Button} from "@mantine/core";
import {Link} from "react-router-dom";
import {getEnterText} from "../../Locale/Enter";
import {useContext, useEffect, useState} from "react";
import {SidenavToggleContext} from "./MobileNav";

export default function HeaderMobileButton(props) {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const {setOpened} = useContext(SidenavToggleContext);

    const [variant, setVariant] = useState('subtle');

    useEffect(() => {
        if (window.location.pathname === props.to) {
            setVariant('light')
        } else {
            setVariant('subtle')
        }
    }, [changeToggle, props.to]);

    return (
        <Button
            id={props.matomo_id}
            component={Link}
            to={props.to}
            variant={variant}
            size="md"
            color="dark"
            leftIcon={props.icon}
            onClick={() => {
                setChangeToggle(!changeToggle)
                setOpened(false)
            }}
        >
            {getEnterText(props.text)}
        </Button>
    );
}
