import {CodeMismatchException, ExpiredCodeException, InvalidPasswordException, LimitExceededException, TooManyFailedAttemptsException, TooManyRequestsException, UnknownError, UserNotConfirmedException} from "../Locale/Errors";


export function ErrorConfirmPassword(type) {
    switch (type) {
        case 'CodeMismatchException':
            return CodeMismatchException();
        case 'ExpiredCodeException':
            return ExpiredCodeException();
        case 'InvalidPasswordException':
            return InvalidPasswordException();
        case 'LimitExceededException':
            return LimitExceededException();
        case 'TooManyFailedAttemptsException':
            return TooManyFailedAttemptsException();
        case 'TooManyRequestsException':
            return TooManyRequestsException();
        case 'UserNotConfirmedException':
            return UserNotConfirmedException();
        default:
            //TODO: log error
            return UnknownError();
    }
}