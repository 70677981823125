import {Button, LoadingOverlay, NumberInput, Paper, Select, Stack, Title} from "@mantine/core";
import {getCabinetText} from "../../../Locale/Cabinet";
import {useForm} from "@mantine/form";
import {WithdrawForm} from "../../../forms/appForms";
import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../../misc/Accounts";
import {GameAccountsContext} from "../../Cabinet";
import {ACTIVE_SEVER, API_URL, REMASTERED_DATE_X6} from "../../../config";
import axios from "axios";
import {ShowFailureNotification, ShowSuccessNotification} from "../../../misc/design/Notifications";
import {TogglerContext} from "../../../App";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";
import {useLocalStorage} from "@mantine/hooks";
import {getServerName} from "../../../misc/misc";


export default function DonateInGame() {
    const {getSession} = useContext(AccountContext)
    const {gameAccounts} = useContext(GameAccountsContext);
    const {changeToggle, setChangeToggle} = useContext(TogglerContext);

    const [loading, setLoading] = useState(true)
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
    const [accountSelectData, setAccountSelectData] = useState([])
    const [characterSelectData, setCharacterSelectData] = useState([])
    const [maxAmount, setMaxAmount] = useState(0)

    const form = useForm(WithdrawForm(maxAmount))

    useEffect(() => {
        getSession().then(async data => {
            setMaxAmount(data['custom:balance'])
            setLoading(false)
        })

        setAccountSelectData(gameAccounts.filter(account => {
            return account.characters.length

        }).map(account => account.login))
    }, [changeToggle, gameAccounts, getSession])

    const selectAccount = (value) => {
        form.setFieldValue('account', value)
        setCharacterSelectData(gameAccounts.filter(account => account.login === value)[0].characters.map(character => character.char_name))
    }

    const withdraw = (values) => {
        setLoading(true)
        getSession().then(({headers}) => {
            const data = {
                account: values.account,
                character: values.character,
                withdrawalAmount: values.amount
            }

            axios.post(`${API_URL}/payment/withdraw/${server}`, data, {headers}).then(() => {
                form.reset()
                setLoading(false)
                setChangeToggle(changeToggle => !changeToggle)
                ShowSuccessNotification(getCabinetText('successWithdraw'))
            }).catch(err => {
                ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                console.error(err.response.data.message)
                setLoading(false)
            })
        })

    }

    return (
        <Paper sx={{maxWidth: 500, minWidth: 325, minHeight: 245, position: 'relative'}}>
            <LoadingOverlay visible={loading} overlayBlur={2}/>

            {!loading &&
                <form id={'c_form_withdraw'} onSubmit={form.onSubmit(() => withdraw(form.values))}>
                    {new Date() < REMASTERED_DATE_X6 + 12 ? <Title order={5} style={{textAlign: 'center', marginTop: 80}} dangerouslySetInnerHTML={{__html: getCabinetText('obt')}}/> :
                        <Stack>
                            {!accountSelectData.length ? <Title order={5} style={{textAlign: 'center', marginTop: 80}} dangerouslySetInnerHTML={{__html: `${getCabinetText('withdrawTitleError')} ${getServerName(server)}`}}/> :
                                <>
                                    <Title order={5} style={{textAlign: 'center'}}>{getCabinetText('withdrawTitle')}</Title>
                                    <Title order={6} style={{textAlign: 'center'}}>{getCabinetText('serverDonateAlert')}<br/> <strong style={{fontSize: 20, color: '#e08821'}}>{getServerName(server)}</strong></Title>
                                    <Select
                                        id={'c_select_withdraw_account'}
                                        label={`${getCabinetText('choose')} ${getCabinetText('smallAccount')}`}
                                        placeholder={getCabinetText('pickOne')}
                                        {...form.getInputProps('account')}
                                        onChange={(value) => selectAccount(value)}
                                        data={accountSelectData}
                                    />

                                    {form.values.account &&
                                        <Select
                                            id={'c_select_withdraw_character'}
                                            label={`${getCabinetText('choose')} ${getCabinetText('smallCharacter')}`}
                                            placeholder={getCabinetText('pickOne')}
                                            {...form.getInputProps('character')}
                                            data={characterSelectData}
                                        />
                                    }
                                    {form.values.character &&
                                        <NumberInput
                                            id={'c_input_withdraw_amount'}
                                            withAsterisk={false}
                                            defaultValue={5}
                                            min={1}
                                            icon={"DC"}
                                            label={getCabinetText('enterAmountDC')}
                                            max={maxAmount}
                                            {...form.getInputProps('amount')}
                                        />
                                    }
                                    {form.values.account && form.values.character &&
                                        <Button id={'c_button_submit_withdraw'} type="submit">{getCabinetText('withdraw')}</Button>
                                    }
                                </>
                            }
                        </Stack>
                    }
                </form>
            }
        </Paper>
    )
}