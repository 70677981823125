import {Select} from "@mantine/core";
import {useContext} from "react";
import {TogglerContext} from "../../App";


export default function SelectAccountLanguage() {
    const {setChangeToggle} = useContext(TogglerContext);

    const changeLang = (value) => {
        localStorage.setItem('lang', value)
        setChangeToggle((changeToggle) => (!changeToggle))
    }

    return (
        <Select
            id={'c_select_header_lang'}
            size={'xs'}
            sx={{width: 90}}
            value={localStorage.getItem('lang')}
            onChange={(value) => changeLang(value)}
            data={[
                {value: 'en', label: 'English'},
                {value: 'ru', label: 'Русский'},
            ]}
        />
    );
}