import {createContext} from 'react';
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import Pool from "./UserPool";


const AccountContext = createContext(null);


const Account = props => {
    const getSession = async () => await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession(async (err, session) => {
                if (err) {
                    if (err.message === 'Cannot retrieve a new session. Please authenticate.') {
                        logout();
                    }
                    reject();
                } else {
                    const attributes = await new Promise((resolve, reject) => {
                        user.getUserAttributes((err, attributes) => {
                            if (err) {
                                reject(err);
                            } else {
                                const results = {};

                                for (let attribute of attributes) {
                                    const {Name, Value} = attribute;
                                    results[Name] = Value;
                                }

                                resolve(results);
                            }
                        })
                    });

                    const token = session.getIdToken().getJwtToken();

                    resolve({
                        user,
                        headers: {
                            Authorization: token
                        },
                        ...session,
                        ...attributes
                    });
                }
            })
        } else {
            reject();
        }
    });

    const authenticate = async (Username, Password) => await new Promise((resolve, reject) => {
        const user = new CognitoUser({Username, Pool});
        const authDetails = new AuthenticationDetails({Username, Password});

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                resolve(data);
            }, onFailure: err => {
                console.error('onFailure', err);
                reject(err)
            }, newPasswordRequired: data => {
                resolve(data);
            }
        });
    })

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            window.location.href = '/login';
        }
    }

    return (
        <AccountContext.Provider value={{authenticate, getSession, logout}}>
            {props.children}
        </AccountContext.Provider>
    );
}

export {Account, AccountContext};