import {useEffect} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {API_URL, COOKIE_DOMAIN} from "../config";


export default function Promo() {
    const {referralKey} = useParams()
    const navigate = useNavigate();
    
    useEffect(() => {
        if (document.cookie.split('; ').find((row) => row.startsWith('referral_key='))?.split('=')[1]){
            navigate(`/`)
            return
        }
        axios.post(`${API_URL}/referral/promo`, {referralKey, source: 'cabinet'}).then(() => {
            let date = new Date(new Date().setMonth(new Date().getMonth()+1)).toUTCString();
            document.cookie = `referral_key=${referralKey};path=/;domain=.${COOKIE_DOMAIN};expires=${date}`
            navigate(`/`)
        })
    }, [navigate, referralKey])
}