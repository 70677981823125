import {Button, Group, Modal, Select, Stack, TextInput, Input} from "@mantine/core";
import {useContext, useState} from "react";
import {Plus, Refresh} from "tabler-icons-react";
import {useForm} from "@mantine/form";
import axios from "axios";
import {ACTIVE_SEVER, API_URL} from "../../../config";
import {AccountContext} from "../../../misc/Accounts";
import {getCabinetText} from "../../../Locale/Cabinet";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";
import {GameAccountsContext} from "../../Cabinet";
import {ShowFailureNotification, ShowSuccessNotification} from "../../../misc/design/Notifications";
import {CreateGameAccountForm} from "../../../forms/appForms";
import PassInput from "../../../components/PassInput";
import {useLocalStorage, useViewportSize} from "@mantine/hooks";
import {getTextNew} from "../../../Locale/Enter";


function generatePrefix() {
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz";

    for (let i = 0; i < 3; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}


function generatePrefixData() {
    let data = []
    for (let i = 0; i < 5; i++) {
        let prefix = generatePrefix()
        data.push({value: prefix, label: prefix})
    }
    return data
}


export default function ModalCreateGameAccount(props) {
    const {getSession} = useContext(AccountContext)
    const {gameAccounts} = useContext(GameAccountsContext);
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
    const {width} = useViewportSize();
    const [opened, setOpened] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [prefixes, setPrefixes] = useState(generatePrefixData())

    const form = useForm(CreateGameAccountForm(prefixes));

    const createGameAccount = values => {
        setButtonLoading(true)
        const exist = prefixes.filter(prefix => prefix.value === values.prefix)

        if (exist.length) {
            getSession().then(async ({headers}) => {
                axios.post(`${API_URL}/accounts/create/${server}`, {login: values.login, prefix: values.prefix, password: values.password}, {headers})
                    .then(() => {
                        ShowSuccessNotification(getCabinetText('game_account_created'))
                        props.refreshAccounts()
                        setButtonLoading(false)
                        setOpened(false)
                        form.reset()
                    })
                    .catch(err => {
                        ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                        console.error(err.response.data.message)
                        setButtonLoading(false)
                    })
            })
        }
    }

    const refreshPrefixes = () => {
        const newPrefixes = generatePrefixData()
        setPrefixes(newPrefixes)
        form.setFieldValue('prefix', newPrefixes[0].value);
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={getCabinetText('create_game_account')}
            >
                <form id={'c_form_create_game_account'} onSubmit={form.onSubmit((values) => createGameAccount(values))}>
                    <Stack>
                        <Group position="left" spacing="xs">
                            <Input.Wrapper
                                withAsterisk
                                label={getCabinetText('login')}
                                description={getCabinetText('loginDescription')}
                            >
                                <Group sx={{marginTop: 7}} style={{display: 'flex', alignItems: 'start'}}>
                                    <Button
                                        variant="white"
                                        leftIcon={<Refresh size={14}/>}
                                        style={{width: '5%'}}
                                        styles={{root: {padding: 10}, leftIcon: {color: 'black', margin: 0}}}
                                        onClick={refreshPrefixes}
                                        id={'c_button_refresh_prefixes'}
                                    />
                                    <Select
                                        style={{width: '20%'}}
                                        data={prefixes}
                                        {...form.getInputProps('prefix')}
                                        id={'c_select_prefix'}
                                    />
                                    <TextInput
                                        style={width < 767 ? {width: '60%'} : {width: '66%'}}
                                        placeholder={getCabinetText('login')}
                                        {...form.getInputProps('login')}
                                        id={'c_input_game_account_login'}
                                    />
                                </Group>
                            </Input.Wrapper>
                        </Group>
                        <PassInput matomo_id={'c_input_game_account_password'} description={getTextNew('The password must be at least 4 characters long and contain at least one letter.')} form={form}/>
                        <PassInput matomo_id={'c_input_game_account_confirm_password'} form={form} field="confirmPassword"/>
                    </Stack>


                    <Group position="left" style={{paddingTop: 20, paddingBottom: 20}}>
                        <Button id={'c_button_submit_create_game_account'} type="submit" color="accent" loading={buttonLoading}>{getCabinetText('create')}</Button>
                    </Group>
                </form>
            </Modal>

            <Group position="center">
                {gameAccounts.length < 25 &&
                    <Button id={'c_button_create_game_account'} variant="filled" color="godlike" radius="xl" leftIcon={<Plus size={14}/>} onClick={() => setOpened(true)}>{`${getCabinetText('create_game_account')} (${gameAccounts.length}/25)`} </Button>
                }
            </Group>
        </>
    );
}