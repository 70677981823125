import {Button, Card, Center, Checkbox, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import {Book, Login, User} from "tabler-icons-react";
import UserPool from "../misc/UserPool";
import {useContext, useEffect, useRef, useState} from "react";
import {useForm} from "@mantine/form";
import {ErrorConfirmRegistration} from "../Errors/ErrorConfirmRegistration";
import ResendCode from "./components/ResendCode";
import {SignUpExceptionHandler} from "../Errors/ErrorSignUp";
import GrayButton from "./components/GrayButton";
import SelectLanguage from "./components/SelectLanguage";
import {getEnterText, getTextNew} from "../Locale/Enter";
import {HeaderToggleContext} from "./EnterPage";
import {ShowFailureNotification, ShowSuccessNotification} from "../misc/design/Notifications";
import {SignUpConfirmForm, SignUpSendCodeForm} from "../forms/enterForms";
import {getUser} from "../misc/getUser";
import CodeInput from "../components/CodeInput";
import EmailInput from "../components/EmailInput";
import PassInput from "../components/PassInput";
import Welcome from "./components/Welcome";
import {useParams} from "react-router-dom";
import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import axios from "axios";
import {API_URL} from "../config";
import ReferralInput from "../components/ReferralInput";
import useQueryParam from "../misc/hooks/useQueryParam";
import {getCookieValue, is_referral_key_exist, isEmpty} from "../misc/misc";
import {ErrorResendCode} from "../Errors/ErrorResendCode";

export default function SignUp() {
    const theme = useMantineTheme();

    const {changeToggle} = useContext(HeaderToggleContext);
    const prevChangeToggle = useRef();

    let {code} = useParams();
    const email = useQueryParam('email')

    const [loading, setLoading] = useState(false);
    const [startResendDate, setStartResendDate] = useState(Date.now())
    const [stage, setStage] = useState(code ? 2 : 1);
    const [disabled, setDisabled] = useState(!!code);
    const [showReferralKeyInput] = useState(!is_referral_key_exist())

    const formSendCode = useForm(SignUpSendCodeForm);
    const formConfirmRegistration = useForm(SignUpConfirmForm(code ? code : ''));

    useEffect(() => {
        if (prevChangeToggle.current !== changeToggle) {
            if (!isEmpty(formSendCode.errors)) {
                formSendCode.validate();
            }
            if (!isEmpty(formConfirmRegistration.errors)) {
                formConfirmRegistration.validate();
            }

            prevChangeToggle.current = changeToggle;
        }
    }, [changeToggle, formConfirmRegistration, formSendCode])

    useEffect(() => {
        if (code && email && !Object.keys(formConfirmRegistration.errors).length && !loading) {
            setLoading(true);
            getUser(email).confirmRegistration(code, true, err => {
                if (err) {
                    setDisabled(false);
                    ShowFailureNotification(ErrorConfirmRegistration(err));
                    formConfirmRegistration.setErrors({confirmationCode: ErrorConfirmRegistration(err)});
                    setLoading(false);
                    return;
                }
                ShowSuccessNotification(getEnterText('successConfirmSignUp'))
                setInterval(() => {
                    window.location.href = "/login";
                }, 2000);
            });
        }
    }, [code, email, formConfirmRegistration, loading]);

    const sendCode = values => {
        setLoading(true);

        let attribute = new CognitoUserAttribute({
            Name: 'custom:agree_to_com',
            Value: values.agreeToCommunication ? '1' : '0'
        });

        UserPool.signUp(values.email.toLowerCase(), values.password, [attribute], null, (err, data) => {
            if (err) {
                if (err.name === 'UsernameExistsException') {
                    getUser(values.email.toLowerCase()).resendConfirmationCode((err, result) => {
                        if (err) {
                            setLoading(false);
                            ShowFailureNotification(ErrorResendCode(err));
                        } else {
                            setStartResendDate(Date.now())
                            setLoading(false);
                            setStage(2);
                        }
                    });
                    return;
                }
                if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                    ShowSuccessNotification(getEnterText('successConfirmSignUp'))
                    setInterval(() => {
                        window.location.href = "/login";
                    }, 2000);
                    return;
                }
                setLoading(false);
                ShowFailureNotification(getTextNew(err.message))
                formSendCode.setErrors(SignUpExceptionHandler(err))
                return
            }
            axios.post(`${API_URL}/referral/registration`, {
                cognitoId: data.userSub,
                email: values.email.toLowerCase(),
                referralKey: !showReferralKeyInput ? (getCookieValue('referral_key') || '') : formSendCode.values.referral?.toLowerCase()
            }).then(() => {
                setStartResendDate(Date.now())
                setLoading(false);
                setStage(2);
            })
        });
    }

    const confirm = values => {
        setLoading(true);
        getUser(email ?? formSendCode.values.email).confirmRegistration(values.confirmationCode, true, err => {
            if (err) {
                setDisabled(false);
                ShowFailureNotification(ErrorConfirmRegistration(err));
                formConfirmRegistration.setErrors({confirmationCode: ErrorConfirmRegistration(err)});
                setLoading(false);
                return;
            }
            ShowSuccessNotification(getEnterText('successConfirmSignUp'))
            setInterval(() => {
                window.location.href = "/login";
            }, 2000);
        });
    }

    return (
        <Center>
            <Stack>
                <Welcome/>
                <Card>
                    <Card.Section sx={{background: theme.fn.linearGradient(120, 'rgba(224, 136, 33, 1)', 'rgb(155,98,19)'), padding: 10}}>
                        <Group position="apart">
                            <Text size="sm" sx={{color: theme.colors.gray[0]}}>{getEnterText('signUp')}</Text>
                            <SelectLanguage/>
                        </Group>
                    </Card.Section>
                    {stage === 1 &&
                        <form id={'c_form_signup_step_1'} onSubmit={formSendCode.onSubmit((values) => sendCode(values))}>
                            <Card.Section>
                                <Stack>
                                    <EmailInput matomo_id={'c_input_email'} form={formSendCode} description/>
                                    <PassInput matomo_id={'c_input_password'} form={formSendCode} description/>
                                    <PassInput matomo_id={'c_input_confirm_password'} form={formSendCode} field="confirmPassword"/>
                                    {showReferralKeyInput && <ReferralInput matomo_id={'c_input_referral'} form={formSendCode}/>}
                                </Stack>
                            </Card.Section>

                            <Card.Section>
                                <Stack>
                                    <Checkbox
                                        id={'c_checkbox_agree'}
                                        required
                                        label={<>{getEnterText('agree')}<a href="/OpenServer/domains/localhost/Projects/L2/account/public">{getEnterText('rules')}</a></>}
                                        {...formSendCode.getInputProps('agreeTerms', {type: 'checkbox'})}
                                    />
                                    <Checkbox
                                        id={'c_checkbox_agree_communication'}
                                        label={getEnterText('agreeToCommunication')}
                                        {...formSendCode.getInputProps('agreeToCommunication', {type: 'checkbox'})}
                                    />
                                    <Group position="right">
                                        <Button id={'c_button_submit_signup_step_1'} type="submit" leftIcon={<Login size={18}/>}
                                                loading={loading}>{getEnterText('register')}</Button>
                                    </Group>
                                </Stack>
                            </Card.Section>
                        </form>
                    }

                    {stage === 2 &&
                        <form id={'c_form_signup_step_2'} onSubmit={formConfirmRegistration.onSubmit((values) => confirm(values))}>
                            <Card.Section>
                                <Stack>
                                    <Center><Text align="center">{getEnterText('codeSended')}</Text></Center>
                                    <CodeInput matomo_id={'c_input_code'} form={formConfirmRegistration} disabled={disabled}/>
                                </Stack>
                            </Card.Section>

                            <Card.Section>
                                <Group position="apart">
                                    <ResendCode matomo_id={'c_button_resend_code'} email={formSendCode.values.email} date={startResendDate}
                                                setDate={setStartResendDate}>{getEnterText('resendCode')}</ResendCode>
                                    <Button id={'c_button_submit_signup_step_2'} type="submit" leftIcon={<Login size={18}/>} loading={loading}>{getEnterText('confirm')}</Button>
                                </Group>
                            </Card.Section>
                        </form>
                    }

                    <Card.Section sx={{backgroundColor: theme.colors.gray[1]}}>
                        <Group position="center">
                            <GrayButton matomo_id={'c_button_rules'} href={'https://dares.club/user'} leftIcon={<Book size={18}/>}>
                                {getEnterText('readRules')}
                            </GrayButton>
                            <GrayButton matomo_id={'c_button_login'} to="/login" leftIcon={<User size={18}/>}>
                                {getEnterText('authorize')}
                            </GrayButton>
                        </Group>
                    </Card.Section>
                </Card>
            </Stack>
        </Center>
    );
}