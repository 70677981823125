import {getEnterText} from "../Locale/Enter";
import {TextInput} from "@mantine/core";


export default function EmailInput(props) {

    return (
        <TextInput
            id={props.matomo_id}
            label="E-mail"
            placeholder="E-mail"
            description = {props.description && getEnterText('emailDescription')}
            {...props.form.getInputProps('email')}
        />
    )
}