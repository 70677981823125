import {Group} from "@mantine/core";
import DonateHistory from "../components/Donate/DonateHistory";
import DonateCard from "../components/Donate/DonateCard";
import {useContext, useEffect} from "react";
import {TogglerContext} from "../../App";
import DonateInGame from "../components/Donate/DonateInGame";
import {useLocalStorage} from "@mantine/hooks";
import {ACTIVE_SEVER, REMASTERED_DATE_NEONATE_1} from "../../config";


export default function Donate() {
    const {changeToggle} = useContext(TogglerContext);
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})

    useEffect(() => {
    }, [changeToggle])

    return (
        <Group sx={{alignItems: 'flex-start'}}>
            <DonateCard/>
            {(server !== 'neonate' || new Date() > (new Date(REMASTERED_DATE_NEONATE_1) - (2 * 60 * 60 * 1000))) && <DonateInGame/>}
            <DonateHistory/>
        </Group>
    );
}