import {Button, Group, Modal, Stack} from "@mantine/core";
import {useContext, useState} from "react";
import axios from "axios";
import {ACTIVE_SEVER, API_URL} from "../../../config";
import {AccountContext} from "../../../misc/Accounts";
import {getCabinetText} from "../../../Locale/Cabinet";
import {useForm} from "@mantine/form";
import {ShowFailureNotification, ShowSuccessNotification} from "../../../misc/design/Notifications";
import {ResetPasswordGameAccountForm} from "../../../forms/appForms";
import PassInput from "../../../components/PassInput";
import ErrorApiResponse from "../../../Errors/ErrorApiResponse";
import {useLocalStorage} from "@mantine/hooks";
import {getTextNew} from "../../../Locale/Enter";


export default function ModalResetPasswordGameAccount(props) {
    const {getSession} = useContext(AccountContext)

    const [opened, setOpened] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})

    const form = useForm(ResetPasswordGameAccountForm);

    const resetPasswordGameAccountHandler = values => {
        setButtonLoading(true)

        getSession().then(async ({headers}) => {
            axios.post(`${API_URL}/accounts/reset/${server}`, {login: props.login, password: values.password}, {headers})
                .then(() => {
                    ShowSuccessNotification(getCabinetText('game_account_password_reset'))
                    setButtonLoading(false)
                    setOpened(false)
                    form.reset()
                })
                .catch(err => {
                    ShowFailureNotification(ErrorApiResponse(err.response.data.message))
                    console.error(err.response.data.message)
                    setButtonLoading(false)
                })
        })
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={getCabinetText('reset_password_game_account')}
            >
                <form id={'c_form_reset_game_account_password'} onSubmit={form.onSubmit((values) => resetPasswordGameAccountHandler(values))}>
                    <Stack>
                        <PassInput matomo_id={'c_input_game_account_password'} form={form} description={getTextNew('The password must be at least 4 characters long and contain at least one letter.')}/>
                        <PassInput matomo_id={'c_input_game_account_confirm_password'} form={form} field="confirmPassword"/>
                    </Stack>

                    <Group position="left" style={{paddingTop: 20}}>
                        <Button id={'c_button_submit_reset_game_account_password'} type="submit" variant="filled" color="godlike" loading={buttonLoading}>{getCabinetText('resetPassword')}</Button>
                    </Group>
                </form>
            </Modal>

            <Group position="center">
                <Button id={'c_button_reset_game_account_password'} variant="subtle" color="subtle" onClick={() => setOpened(true)}>{getCabinetText('resetPassword')}</Button>
            </Group>
        </>
    );
}