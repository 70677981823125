import {Button} from "@mantine/core";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useViewportSize} from "@mantine/hooks";
import {TogglerContext} from "../../App";
import {GoogleAnalyticsButton} from "../../misc/GoogleAnalitics";


export default function NewLink(props) {
    const {changeToggle, setChangeToggle} = useContext(TogglerContext);
    const {width} = useViewportSize();
    const [color, setColor] = useState('#fff');

    useEffect(() => {
        if (window.location.pathname === props.to) {
            setColor('#e08821')
        } else {
            setColor('#fff')
        }
    }, [changeToggle, props.to]);

    const closeSidebar = () => {
        GoogleAnalyticsButton()
        if (width < 1000) {
            props.setOpened(false);
        }
        setChangeToggle(!changeToggle)
    }

    return (
        <li style={{textDecoration: 'none', listStyleType: 'none', marginBottom: 20}}>
            <Button
                id={props.matomo_id}
                component={Link}
                to={props.to}
                variant="unstyled"
                size="md"
                radius={0}
                color="dark"
                styles={(theme) => ({
                    root: {
                        width: 200,
                        backgroundColor: '#0e0e0e50',
                        color: color,
                        '&:hover': {
                            backgroundColor: theme.fn.darken('#0e0e0e', 0.05),
                        },
                    },
                    inner: {
                        justifyContent: 'start',
                    },

                    leftIcon: {
                        marginRight: 15,
                    },
                })}
                onClick={closeSidebar}
            >
                {props.children}
            </Button>
        </li>
    );
}