import {TextInput} from "@mantine/core";
import {getEnterText} from "../Locale/Enter";


export default function ReferralInput(props) {
    return (
        <TextInput
            id={props.matomo_id}
            withAsterisk={false}
            required={false}
            label={getEnterText('referralTitle')}
            placeholder={getEnterText('referralTitle')}
            {...props.form.getInputProps('referral')}
        />
    );
}