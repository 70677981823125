import {Box, Burger, Drawer, Group, MediaQuery, Title, useMantineTheme} from "@mantine/core";
import {Login, UserPlus} from "tabler-icons-react";
import HeaderMobileButton from "./HeaderMobileButton";
import {DOMAIN_URL} from "../../config";
import {createContext, useState} from "react";


export const SidenavToggleContext = createContext()


export default function MobileNav() {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);

    return (
        <MediaQuery largerThan="md" styles={{display: 'none'}}>
            <Group position="apart">

                <Group>
                    <Title order={3} className="header-logo">
                        <a id={'c_link_logo'} href={DOMAIN_URL}>Dares</a>
                    </Title>
                </Group>
                <Group>
                    <Drawer
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title={
                            <Title order={3} className="header-logo">
                                <a id={'c_link_logo'} href={DOMAIN_URL}>Dares</a>
                            </Title>
                        }
                        withCloseButton={true}
                    >
                        <SidenavToggleContext.Provider value={{setOpened}}>
                            <Box sx={{display: "flex", flexDirection: 'column'}}>
                                {/*<HeaderMobileButton to="/rating" icon={<ChartBar size={14}/>} text={'rating'}/>*/}
                                {/*<HeaderMobileButton to="/shop" icon={<ShoppingCart size={14}/>} text={'shop'}/>*/}
                                {/*<HeaderMobileButton to="/donation" icon={<Diamond size={14}/>} text={'donate'}/>*/}
                                <HeaderMobileButton matomo_id={'c_button_mobile_login'} to="/login" icon={<Login size={14}/>} text={'login'}/>
                                <HeaderMobileButton matomo_id={'c_button_mobile_signup'} to="/signup" icon={<UserPlus size={14}/>} text={'registration'}/>
                            </Box>
                        </SidenavToggleContext.Provider>
                    </Drawer>

                    <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                        id={'c_burger'}
                    />
                </Group>
            </Group>
        </MediaQuery>
    )
}