import {Select} from "@mantine/core";
import {HeaderToggleContext} from "../EnterPage";
import {useContext} from "react";


export const getLang = () => {
    return localStorage.getItem('lang')
}


export default function SelectLanguage() {
    const {setChangeToggle} = useContext(HeaderToggleContext);

    const changeLang = (value) => {
        localStorage.setItem('lang', value)
        setChangeToggle((changeToggle) => (!changeToggle))
    }

    return (
        <Select
            id={'c_select_lang'}
            size={'xs'}
            sx={{width: 75}}
            value={localStorage.getItem('lang')}
            onChange={(value) => changeLang(value)}
            data={[
                {value: 'en', label: 'EN'},
                {value: 'ru', label: 'RU'},
            ]}
        />
    );
}