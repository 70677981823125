import {getLang} from "../../EnterPage/components/SelectLanguage";
import {showNotification} from "@mantine/notifications";

export function ShowSuccessNotification(message) {
    if (getLang() === 'ru') {
        showNotification({
            title: 'Успешно',
            message: message,
            color: 'green',
        });
    }
    if (getLang() === 'en') {
        showNotification({
            title: 'Success',
            message: message,
            color: 'green',
        });
    }
}


export function ShowFailureNotification(message) {
    if (getLang() === 'ru') {
        showNotification({
            title: "Ошибка",
            message: message,
            color: 'red',
        })
    }
    if (getLang() === 'en') {
        showNotification({
            title: "Error",
            message: message,
            color: 'red',
        })
    }
}