import React, {useContext, useState} from 'react';
import {ActionIcon, Autocomplete, Button, Group, Modal, NumberInput, Select, Space, Stack, TextInput, Title} from "@mantine/core";
import {AccountContext} from "../../../misc/Accounts";
import {useForm} from "@mantine/form";
import axios from "axios";
import {ACTIVE_SEVER, API_URL} from "../../../config";
import {ShowFailureNotification, ShowSuccessNotification} from "../../../misc/design/Notifications";
import {Plus, Trash} from "tabler-icons-react";
import {useLocalStorage} from "@mantine/hooks";
import {getServerName} from "../../../misc/misc";


const CreatePromocodes = ({itemNames, itemLoading, setPromocodesUpdateTrigger}) => {
        const {getSession} = useContext(AccountContext);
        const [server] = useLocalStorage({key: 'server', defaultValue: ACTIVE_SEVER})
        const [loading, setLoading] = useState(false)
        const [opened, setOpened] = useState(false)


        const form = useForm({
            initialValues: {
                server,
                name: '',
                minLevel: 0,
                maxLevel: 0,
                items: [
                    {name: '', count: 1, enchant: 0},
                ],
                premium: 0,
                amount: 1
            },

            validate: {
                name: (value, values) => (value.length > 0 && values.amount > 1 ? 'Name must be unique' : null),
                amount: (value) => (value < 1 ? 'Amount must be greater than 0' : null),
            },
        })


        const submit = (values) => {
            setLoading(true)
            const {items, ...data} = values
            let preparedItems = items[0].name === '' ? [] : items.map(item => {
                return {id: item.name.split(' - ')[0], count: item.count, enchant: item.enchant}
            })
            if (preparedItems.length === 0 && !values.premium) {
                ShowFailureNotification('At least one item or premium is required')
                setLoading(false)
                return;
            }
            getSession().then(async ({headers}) => {
                axios.post(`${API_URL}/promocodes`, {...data, items: preparedItems, server}, {headers})
                    .then(({data}) => {
                        form.reset()
                        setOpened(false)
                        ShowSuccessNotification(data.message)
                        setPromocodesUpdateTrigger((prev) => !prev)
                    })
                    .catch(err => {
                        ShowFailureNotification(err.response.data.message)
                        console.error(err.response.data.message)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
        }

        const premuimData = [
            {value: 0, label: 'No'},
            {value: 1, label: '12 hours'},
            {value: 2, label: '7 days'},
            {value: 3, label: '30 days'},
            {value: 4, label: '100 days'},

        ]

        const items = form.values.items.map((item, index) => {
            return <Group key={index} mt="xs">
                <Autocomplete
                    disabled={itemLoading}
                    label={'Item'}
                    {...form.getInputProps(`items.${index}.name`)}
                    data={itemNames}
                />
                <NumberInput
                    label={'Count'}
                    min={1}
                    {...form.getInputProps(`items.${index}.count`)}
                />
                <NumberInput
                    label={'Enchant'}
                    min={0}
                    {...form.getInputProps(`items.${index}.enchant`)}
                />
                <ActionIcon color="subtle" style={{alignSelf: 'end'}} onClick={() => form.insertListItem('items', {id: 1, count: 1, enchant: 0})}>
                    <Plus/>
                </ActionIcon>
                <ActionIcon color="red" disabled={index === 0} style={{alignSelf: 'end'}} onClick={() => form.removeListItem('items', index)}>
                    <Trash/>
                </ActionIcon>
            </Group>
        })

        return <>
            <Modal opened={opened} onClose={() => setOpened(false)} styles={{modal: {width: 800, maxWidth: '90%'}}}>
                <Title order={3}>Create Promocodes</Title>
                <Title order={4} style={{color: '#e08821'}}>{getServerName(server)}</Title>
                <Space h={20}/>
                <form onSubmit={form.onSubmit(submit)}>
                    <Stack>
                        <TextInput
                            label={'Name'}
                            {...form.getInputProps('name')}
                            required={false}
                        />
                        <NumberInput
                            label={'Min level'}
                            min={0}
                            {...form.getInputProps('minLevel')}
                        />
                        <NumberInput
                            label={'Max level'}
                            min={0}
                            {...form.getInputProps('maxLevel')}
                        />
                        {items}
                        <Select
                            label="With premium"
                            color="slider"
                            data={premuimData}
                            {...form.getInputProps(`premium`)}
                        />
                        <NumberInput
                            label={'amount'}
                            {...form.getInputProps('amount')}
                        />
                        <Button type="submit" loading={loading}>Submit</Button>
                    </Stack>
                </form>
            </Modal>
            <Group position="center">
                <Button onClick={() => setOpened(true)}>Create Promocode</Button>
            </Group>
        </>;
    }
;

export default CreatePromocodes;