import {LoadingOverlay, NumberInput, Paper, Button, Slider, Stack, Text, ActionIcon, Title, Group, Box} from "@mantine/core";
import {useForm} from "@mantine/form";
import {PaymentAmountForm} from "../../../forms/appForms";
import React, {useState} from "react";
import {ArrowNarrowLeft} from "tabler-icons-react";
import {getCabinetText} from "../../../Locale/Cabinet";
import StripePaymentButton from "./StripePaymentButton";
import FreekassaPaymentButton from "./FreekassaPaymentButton";
import {REMASTERED_DATE_X6} from "../../../config";
import {calculateCoins, isPromotionActive} from "../../../misc/misc";


const promotions = [
    {oldAmount: 50, newAmount: 75, cost: 4.99, accent: false},
    {oldAmount: 150, newAmount: 250, cost: 14.99, accent: false},
    {oldAmount: 500, newAmount: 900, cost: 49.99, accent: true},
    {oldAmount: 1000, newAmount: 2500, cost: 99.99, accent: false},
]

export default function DonateCard() {
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1);

    const form = useForm(PaymentAmountForm)

    const [coins, setCoins] = useState(isPromotionActive() ? 75 : 50);

    const setBonusCoins = (value) => {
        if (value === undefined) {
            setCoins(0)
            return
        }

        setCoins(calculateCoins(value));
    }

    const handleBonusClick = (value) => {
        form.setFieldValue('amount', value)
        setStep(2)
    }

    const handleBack = () => {
        setStep(1)
        form.setFieldValue('amount', 5)
        setCoins(isPromotionActive() ? 75 : 50)
    }

    return (
        <Box sx={{width: '100%', position: 'relative'}}>
            <LoadingOverlay visible={loading} overlayBlur={2}/>
            {step === 1 &&
                <form id={'c_form_donate'} onSubmit={form.onSubmit(() => setStep(2))}>
                    <Group position={"apart"}>
                        {isPromotionActive() ?
                            <Paper style={{display: 'flex', flexDirection: 'column', alignSelf: 'stretch', justifyContent: 'center', flexGrow: 3}}>
                                <Stack>
                                    <Title order={2} style={{textAlign: 'center'}}>Choose ready amount Dares Coins</Title>
                                    <Group position="apart">
                                        {promotions.map((promotion, index) => (
                                            <Stack key={index} spacing="xs" className={`styled-border ${promotion.accent ? 'accent' : ''}`}>
                                                <Text style={{width: '100%', textAlign: 'center'}}><s>{}{promotion.oldAmount} DC</s> <span style={{color: `${promotion.accent ? '#e08821' : 'black'}`}}>{promotion.newAmount}</span> DC</Text>
                                                <Text style={{width: '100%', textAlign: 'center'}}>For</Text>
                                                <Button variant={`${promotion.accent ? 'light' : 'filled'}`} onClick={() => handleBonusClick(promotion.cost)} color={`${promotion.accent ? 'light' : 'dark'}`}>Pay € {promotion.cost}</Button>
                                            </Stack>
                                        ))}
                                    </Group>
                                </Stack>
                            </Paper>
                            : null}
                        <Paper style={{display: 'flex', flexDirection: 'column', alignSelf: 'stretch', justifyContent: 'center', flexGrow: 1, height: 260}}>
                            <Stack spacing={"xs"}>
                                {isPromotionActive() ? <Title order={4} style={{textAlign: 'center'}}>Or Enter Custom Amount</Title> :  <Title order={4} style={{textAlign: 'center'}}>{getCabinetText('daresCoinsDonate')}</Title>}
                                <NumberInput
                                    withAsterisk
                                    defaultValue={10}
                                    min={5}
                                    icon={"€"}
                                    {...form.getInputProps('amount')}
                                    onChange={(value) => {
                                        setBonusCoins(value)
                                        form.setFieldValue('amount', value ? value : 0)
                                    }}
                                    id={'c_input_donate_amount'}
                                />

                                <Slider
                                    defaultValue={10}
                                    min={5}
                                    max={500}
                                    label={(value) => `${value} €`}
                                    {...form.getInputProps('amount')}
                                    onChange={(value) => {
                                        setBonusCoins(value);
                                        form.setFieldValue('amount', value ? value : 0)
                                    }}
                                />

                                <Text align="center">Dares Coins: {form.values.amount * 10} <Text
                                    style={{color: 'green'}}>{getCabinetText('bonus')}: + {coins - form.values.amount * 10}</Text>{getCabinetText('total')}: {coins}</Text>
                                <Button id={'c_button_submit_donate'} type="submit" variant={'filled'} color="dark">{getCabinetText('toPay')}</Button>
                            </Stack>
                        </Paper>
                    </Group>
                </form>
            }
            {step === 2 &&
                (new Date() + 12 < REMASTERED_DATE_X6 ? <Title order={5} style={{textAlign: 'center', height: 100, marginTop: 50}} dangerouslySetInnerHTML={{__html: getCabinetText('obt')}}/> :
                        <Paper style={{height: 260, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <ActionIcon onClick={handleBack}>
                                <ArrowNarrowLeft size={24}/>
                            </ActionIcon>

                            <Stack spacing={"lg"} style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                                <Title order={4} align="center">{getCabinetText('choosePayments')}</Title>
                                <Stack>
                                    <Group grow sx={{alignItems: 'flex-start'}}>
                                        <StripePaymentButton form={form} setLoading={setLoading}/>
                                        <FreekassaPaymentButton form={form} setLoading={setLoading} amount={form.values.amount}/>
                                    </Group>
                                </Stack>
                            </Stack>
                        </Paper>
                )
            }
        </Box>
    )
}