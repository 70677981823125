import GameAccounts from "../components/Home/GameAccounts";
import {Center} from "@mantine/core";


export default function Home() {

    return (
        <Center sx={{marginTop: 60}}>
            <GameAccounts/>
        </Center>
    );
}