import {Navigate, Route, Routes} from "react-router-dom";
import EnterPage from "../EnterPage/EnterPage";
import SignIn from "../EnterPage/SignIn";
import SignUp from "../EnterPage/SignUp";
import Reminder from "../EnterPage/Reminder";
import Promo from "../EnterPage/Promo";
import Unsubscribe from "../Cabinet/pages/Unsubscribe";


export default function UnauthenticatedRoutes() {
    return (
        <EnterPage>
            <Routes>
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/signup/:code" element={<SignUp/>}/>
                <Route path="/reminder" element={<Reminder/>}/>
                <Route path="/promo/:referralKey" element={<Promo/>}/>
                <Route path="/unsubscribe/:cognitoId" element={<Unsubscribe/>}/>
                {/*<Route*/}
                {/*    path="/rating"*/}
                {/*    element={*/}
                {/*        <Container>*/}
                {/*            <Rating/>*/}
                {/*        </Container>*/}
                {/*    }*/}
                {/*/>*/}
                <Route path="/*" element={<Navigate to={`/login`}/>}/>
            </Routes>
        </EnterPage>
    )
}