import React, {useContext} from 'react';
import {ActionIcon, Center, Loader} from '@mantine/core';
import {Refresh} from "tabler-icons-react";
import {TogglerContext} from "../App";


export default function NewLoading(props) {
    const {setReload} = useContext(TogglerContext);
    return (props.loading ? <Center sx={props.sx}><Loader variant="dots" color="dark" sx={{marginTop: 250}}/></Center> : props.error ?
        <ActionIcon variant="outline" color="godlike" radius="xl" size="lg" sx={{marginTop: 250}} onClick={() => setReload(o => !o)}><Refresh size={24}/></ActionIcon> : props.children)
}