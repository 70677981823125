import {Box, Group, Header, MediaQuery, Stack, Title} from "@mantine/core";
import {Login, UserPlus} from "tabler-icons-react";
import HeaderButton from "./components/HeaderButton";
import {DOMAIN_URL} from "../config";
import MobileNav from "./components/MobileNav";


export default function EnterHeader() {

    return (
        <Header height="auto">
            <Stack sx={{padding: 5}}>
                <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Group>
                            <Title order={3} className="header-logo">
                                <a href={DOMAIN_URL} id={'c_link_logo'}>Dares</a>
                            </Title>
                        </Group>

                        <Group>
                            {/*<HeaderButton to="/rating" icon={<ChartBar size={14}/>} text={'rating'}/>*/}
                            {/*<HeaderButton to="/shop" icon={<ShoppingCart size={14}/>} text={'shop'}/>*/}
                            {/*<HeaderButton to="/donation" icon={<Diamond size={14}/>} text={'donate'}/>*/}
                        </Group>

                        <Group>
                            <HeaderButton matomo_id={'c_button_header_login'} to="/login" icon={<Login size={14}/>} text={'login'}/>
                            <HeaderButton matomo_id={'c_button_header_signup'} to="/signup" icon={<UserPlus size={14}/>} text={'registration'}/>
                        </Group>
                    </Box>
                </MediaQuery>

                <MobileNav/>

            </Stack>
        </Header>
    );
}