let TRACKING_ID;
if (window.location.href.split('/')[2] === 'cabinet.dares.club') {
    TRACKING_ID = "G-Y8W5BX6GEZ";
} else {
    TRACKING_ID = "G-BRDCEK7PH2";
}

export const GoogleAnalyticsButton = () => {
    const gtag = window.gtag;
    gtag('config', TRACKING_ID, {
        'page_title': document.title,
        'page_location': window.location.href,
        'page_path': window.location.pathname
    })
}