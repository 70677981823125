import {E10, E4, UnknownError} from "../Locale/Errors";


export default function ErrorApiResponse(type) {
    switch (type) {
        case 'E10':
            return E10();
        case 'E4':
            return E4();
        default:
            return UnknownError();
    }
}