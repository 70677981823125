import {Button, Container, Table} from "@mantine/core";
import {useContext, useState} from "react";
import {AccountContext} from "../../../misc/Accounts";
import {getCabinetText} from "../../../Locale/Cabinet";
import {useViewportSize} from "@mantine/hooks";


export default function Devices(props) {
    const {getSession} = useContext(AccountContext);
    const [loading, setLoading] = useState(false)
    const {width} = useViewportSize();

    const forgetDevice = (deviceKey) => {
        setLoading(true)
        getSession()
            .then(({user}) => {
                user.forgetSpecificDevice(deviceKey, {
                    onSuccess: () => {
                        props.setChangeToggle(changeToggle => !changeToggle)
                        setTimeout(() => setLoading(false), 1000)
                    },
                    onFailure: err => {
                        console.error(err)
                        setLoading(false)
                    }
                })
            })
            .catch((err) => {console.error(err); setLoading(false)})
    }

    return (
        <Container size={"xl"} sx={width > 450 ? {paddingTop: 20, paddingBottom: 20} : {padding: '20px 0'}}>
            <Table highlightOnHover>
                <thead>
                <tr style={{textAlign: 'center'}}>
                    <td>{getCabinetText('device')}</td>
                    <td>IP</td>
                    <td></td>
                </tr>
                </thead>
                <tbody style={{textAlign: 'center'}}>
                {props.devices.Devices.map(device => (
                    <tr key={device.DeviceKey}>
                        <td>{device.DeviceAttributes[1].Value === 'nodejs' ? 'updater' : device.DeviceAttributes[1].Value}</td>
                        <td style={{width: 50}}>{device.DeviceAttributes[2].Value}</td>
                        <td><Button loading={loading} onClick={() => forgetDevice(device.DeviceKey)}>{getCabinetText('logout')}</Button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Container>

    )
}