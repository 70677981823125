import {cardStyle, drawerStyles, passwordInputStyle, selectStyle, tabStyle, textInputStyle} from "./Styles";
import {MantineProvider, useMantineTheme} from "@mantine/core";


export default function MantineTheme(props) {
    const theme = useMantineTheme();

    return (
        <MantineProvider
            theme={{
                fontFamily: 'Marcellus SC, Legan, sans-serif',
                colorScheme: 'light',
                colors: {
                    godlike: ['', '', '', '', '', '', '#0e0e0e', '#e08821', '', ''],
                    accent: ['#0e0e0e', '#e08821', '', '', '', '', '#fff', '', '', ''],
                    subtle: ['#e08821', '#e08821', '', '', '', '', '#000', '', '', ''],
                    slider: ['', '', '', '', '', '', '#e08821', '', '', ''],
                    light: ['#e08821', '#e08821', '', '', '', '', '#fff', '', '', ''],
                },
                components: {
                    Tabs: {defaultProps: {variant: 'unstyled'}, styles: tabStyle(theme)},
                    Card: {defaultProps: {shadow: 'sm'}, styles: cardStyle(theme)},
                    Button: {defaultProps: {variant: 'light', size: 'sm', color: 'light'}},
                    Modal: {defaultProps: {centered: true, overlayOpacity: 0.55, overlayBlur: 3}},
                    PasswordInput: {defaultProps: {size: 'sm', required: true, placeholder: '********'}, styles: passwordInputStyle(theme)},
                    TextInput: {defaultProps: {size: 'sm', required: true}, styles: textInputStyle(theme)},
                    NumberInput: {defaultProps: {size: 'sm', required: true}, styles: textInputStyle(theme)},
                    Checkbox: {defaultProps: {size: 'sm', color: 'godlike'}},
                    Paper: {defaultProps: {p: 'sm', shadow: 'xl'}},
                    Slider: {defaultProps: {color: 'slider'}},
                    Select: {styles: selectStyle()},
                    Radio: {defaultProps: {color: 'slider'}},
                    LoadingOverlay: {defaultProps: {loaderProps: {color: 'slider'}}},
                    Loader: {defaultProps: {color: 'slider'}},
                    Drawer: {defaultProps: {withCloseButton: false, size: "md", padding: "xl"}, styles: drawerStyles()},
                    Title: {styles: {root: {fontFamily: 'Marcellus SC, Legan, sans-serif !important'}}}
                }
            }}
            withGlobalStyles
            withNormalizeCSS
        >
            {props.children}
        </MantineProvider>
    )
}