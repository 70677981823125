export const tabStyle = (theme) => {
    return {
        root: {
            boxShadow: theme.shadows.lg,
        },
        tab: {
            backgroundColor: theme.colors.gray[1],
            color: theme.colors.gray[9],
            fontSize: theme.fontSizes.md,
            padding: `${theme.spacing.md}px ${theme.spacing.xl}px`,

            '&[data-active]': {
                backgroundColor: theme.white,
                color: theme.colors.dark[6],
            },
        },
        panel: {
            padding: 0,
            borderRadius: 0,
            backgroundColor: theme.white,
        }
    }
}

export const cardStyle = () => {
    const minWidth = window.screen.width < 767 ? {minWidth: 300} : {minWidth: 400}
    return {
        root: {...minWidth},
        cardSection: {
            padding: 20
        }
    }
}

export const selectStyle = () => {
    return {
        item: {'&[data-selected]': {backgroundColor: 'rgba(224,136,33,0.2)', color: '#e08821', '&:hover': {backgroundColor: 'rgba(224,136,33,0.5)', color: '#e08821'}}},
        input: {'&:focus': {borderColor: '#e08821'}}
    }
}

export const textInputStyle = () => {
    return {
        input: {fontFamily: 'Segoe UI','&:focus': {borderColor: '#e08821'}}
    }
}

export const passwordInputStyle = () => {
    return {
        input: {'&:focus': {borderColor: '#e08821'}, '&:focus-within': {borderColor: '#e08821'}}
    }
}

export const drawerStyles = () => {
    return {
        header: {justifyContent: "center", marginBottom: 40},
    }
}