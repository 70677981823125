import {Burger, Button, Group, Menu, Paper, Title, useMantineTheme, ActionIcon} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../misc/Accounts";
import {Logout, User} from "tabler-icons-react";
import SelectAccountLanguage from "../components/SelectAccountLanguage";
import {getCabinetText} from "../../Locale/Cabinet";
import {Link} from "react-router-dom";
import {TogglerContext} from "../../App";
import {useViewportSize} from "@mantine/hooks";
import SelectServer from "../components/SelectServer";


export default function MyHeader(props) {
    const {logout} = useContext(AccountContext);
    const {getSession} = useContext(AccountContext);
    const {changeToggle, setChangeToggle} = useContext(TogglerContext);
    const theme = useMantineTheme();
    const {width} = useViewportSize();

    const [balance, setBalance] = useState(0)

    useEffect(() => {
        getSession().then(data => setBalance(data['custom:balance']))
    }, [changeToggle, getSession])

    const controlButtons = (
        <>
            <SelectServer/>
            <SelectAccountLanguage/>
            <Button id={'c_button_logout'} variant="subtle" size="md" color="dark" radius="xl" compact rightIcon={<Logout size={15}/>} onClick={logout}>{getCabinetText('logout')}</Button>
        </>
    )

    return (
        <Paper>
            <Group position="apart" sx={{display: "flex", alignItems: "center"}}>
                <Burger
                    id={'c_button_burger'}
                    opened={props.opened}
                    onClick={() => props.setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                />
                {width > 767 ?
                    <Group>
                        <Button
                            component={Link}
                            id={'c_button_balance'}
                            to={'/donate'}
                            variant="unstyled"
                            onClick={() => setChangeToggle(!changeToggle)}
                            style={{color: '#0e0e0e'}}
                        >
                            <Title order={5}>{getCabinetText('balance')}: <span style={{color: '#e08821'}}>{balance ? balance : 0}</span> DC</Title>
                        </Button>
                        {controlButtons}
                    </Group>
                    :
                    <>
                        <Button
                            component={Link}
                            id={'c_button_balance'}
                            to={'/donate'}
                            variant="unstyled"
                            onClick={() => setChangeToggle(!changeToggle)}
                            style={{color: '#0e0e0e'}}
                        >
                            <Title order={5}>{getCabinetText('balance')}: <span style={{color: '#e08821'}}>{balance ? balance : 0}</span> DC</Title>
                        </Button>
                        <Menu
                            size="xl"
                            styles={{
                                body: {
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }
                            }}
                        >
                            <Menu.Target>
                                <ActionIcon>
                                    <User size={18}/>
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Group>
                                    {controlButtons}
                                </Group>
                            </Menu.Dropdown>
                        </Menu>
                    </>
                }
            </Group>
        </Paper>
    );
}