import {ActionIcon, Button, Center, CopyButton, Group, Paper, Space, Text, Tooltip} from "@mantine/core";
import {useViewportSize} from "@mantine/hooks";
import useReferralData from "../../misc/hooks/useReferralData";
import NewLoading from "../../components/NewLoading";
import {Check, Copy} from "tabler-icons-react";

const Referral = () => {
    const {referralData, referralKey, loading, error, createReferralLink} = useReferralData()
    const {width} = useViewportSize();

    return (
        <Center>
            <NewLoading loading={loading} error={error}>
                {!referralKey ?
                    <Button onClick={createReferralLink} sx={{marginTop: 50}}>
                        Create referral link
                    </Button>
                    : <Paper sx={width > 750 ? {width: 750} : {width: '100%'}}>
                        <Group>
                            <Text>Your link: <b>https://dares.club/promo/{referralKey}</b></Text>
                            <CopyButton value={`https://dares.club/promo/${referralKey}`} timeout={2000}>
                                {({copied, copy}) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy link'} withArrow position="right">
                                        <ActionIcon id={'c_button_copy_referral_link'} color={'subtle'} onClick={copy}>
                                            {copied ? <Check size="1rem"/> : <Copy size="1rem"/>}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </Group>
                        <Group>
                            <Text>Your referral key: <b>{referralKey}</b></Text>
                            <CopyButton value={referralKey} timeout={2000}>
                                {({copied, copy}) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy key'} withArrow position="right">
                                        <ActionIcon id={'c_button_copy_referral_key'} color={'subtle'} onClick={copy}>
                                            {copied ? <Check size="1rem"/> : <Copy size="1rem"/>}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </Group>
                        <Space h="xl"/>
                        <Text>Promo: {referralData?.promo}</Text>
                        <Text>Registration: {referralData?.registration}</Text>
                    </Paper>
                }
            </NewLoading>
        </Center>
    );
};

export default Referral;