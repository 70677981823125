import {useContext, useEffect, useState} from 'react';
import {AccountContext} from "../Accounts";
import {useDispatch, useSelector} from "react-redux";
import {ShowFailureNotification, ShowSuccessNotification} from "../design/Notifications";
import ErrorApiResponse from "../../Errors/ErrorApiResponse";
import {TogglerContext} from "../../App";
import axios from "axios";
import {API_URL} from "../../config";


const useReferralData = () => {
    const {getSession} = useContext(AccountContext);
    const {reload} = useContext(TogglerContext);
    const referralData = useSelector(state => state.referralDataState)
    const [referralKey, setReferralKey] = useState(null)

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(false)
        setLoading(true)
    }, [reload])

    useEffect(() => {
        setLoading(true)
        getSession().then((data) => {
            setReferralKey(data['given_name'])
            if ((referralData === null && error === false)) {
                if (data['given_name'] === undefined) {
                    setLoading(false)
                    return
                }
                dispatch.referralDataState.uploadReferralData({data}).then(() => {
                    setLoading(false)
                }).catch(err => {
                    setError(true)
                    setLoading(false)
                    ShowFailureNotification(ErrorApiResponse(err.response.data?.message))
                })
                return
            }
            setLoading(false)
        })
    }, [dispatch.referralDataState, error, getSession, referralData, referralKey])

    const resetReferralData = () => {
        setLoading(true)
        dispatch.referralDataState.setReferralData(null)
    }

    const createReferralLink = () => {
        setLoading(true)
        getSession().then(async (data) => {
            axios.post(`${API_URL}/referral/create-link`, {}, {headers: data.headers}).then((data) => {
                setLoading(false)
                setReferralKey(data.data.message)
                ShowSuccessNotification('Referral link created')
            }).catch(err => {
                setError(true)
                setLoading(false)
                ShowFailureNotification(ErrorApiResponse(err.response.data?.message))
            })
        })
    }

    return {referralData, referralKey, loading, error, resetReferralData, createReferralLink}
};

export default useReferralData;