import {AccountContext} from "./misc/Accounts";
import {createContext, useContext, useEffect, useState} from "react";
import Loading from "./components/Loading";
import UnauthenticatedRoutes from "./components/UnauthenticatedRoutes";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import Pool from "./misc/UserPool";
import {ACTIVE_SEVER, API_URL, SERVER_LIST} from "./config";
import {deleteFramingSymbols, is_referral_key_exist, setReferralKey} from "./misc/misc";
import useQueryParam from "./misc/hooks/useQueryParam";
import axios from "axios";



export const TogglerContext = createContext()


export default function App() {
    const utm_source = useQueryParam('utm_source');

    const user = Pool.getCurrentUser();
    const {getSession} = useContext(AccountContext);

    const [loading, setLoading] = useState(true);
    const [changeToggle, setChangeToggle] = useState(true)
    const [reload, setReload] = useState(true)

    useEffect(() => {
        if (utm_source !== null) {
            if (is_referral_key_exist()) {
                return
            }
            let referralKey = utm_source.toLowerCase()
            setReferralKey(referralKey);
            axios.post(`${API_URL}/referral/promo`, {referralKey, source: 'cabinet'}).then()
        }
    }, [utm_source])

    useEffect(() => {
        getSession()
            .then(() => {
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })

        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en')
        }
        if (localStorage.getItem('lang') === 'eng') {
            localStorage.setItem('lang', 'en')
        }
        if (localStorage.getItem('lang') === 'rus') {
            localStorage.setItem('lang', 'ru')
        }

        if (!SERVER_LIST.includes(deleteFramingSymbols(localStorage.getItem('server')))) {
            localStorage.setItem('server', `"${ACTIVE_SEVER}"`)
        }
    }, [getSession])

    if (!user) {
        return (
            <TogglerContext.Provider value={{changeToggle, setChangeToggle, reload, setReload}}>
                <Loading loading={loading}>
                    <UnauthenticatedRoutes/>
                </Loading>
            </TogglerContext.Provider>
        )
    } else {
        return (
            <TogglerContext.Provider value={{changeToggle, setChangeToggle, reload, setReload}}>
                <Loading loading={loading}>
                    <AuthenticatedRoutes/>
                </Loading>
            </TogglerContext.Provider>
        )
    }
}

